import React, { useEffect, useState } from "react";
import "./style.scss";

import AuthContext from "../AuthContext";
import useData from "../../Hooks/useData";
import moment from "moment/moment";

import Input from "../GUI/Input";
import Button from "../GUI/button";
import Table from "../GUI/table";


export default function ActionScreen() {

    const context = React.useContext(AuthContext);

    const { getData } = useData();

    const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().add(1, 'day').format('YYYY-MM-DD'));

    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [context.authData]);

    async function fetchData() {
        try {
            const data = await getData({ type: 'action_history', from: dateFrom, to: dateTo, search });
            setData(data.length ? data.reverse() : []);
        } catch (e) {
            console.log(e);
        }
    }



    return (
        <div className="ActionScreen">

            <div className="ActionScreenControl">
                <div className="ActionFilterRow">
                    <div className="ActionFilterItem">
                        <span>Дата от:</span>
                        <Input
                            type={'date'}
                            value={dateFrom}
                            onChange={(v) => setDateFrom(v)} />
                    </div>
                    <div className="ActionFilterItem">
                        <span>Дата до:</span>
                        <Input
                            type={'date'}
                            value={dateTo}
                            onChange={(v) => setDateTo(v)} />
                    </div>
                </div>

                <div className="ActionFilterRow">
                    <Input flex={1} title={'Поиск'} value={search} onChange={text => setSearch(text)} />
                </div>


                <div className="ActionFilterRow">
                    <div></div>
                    <Button
                        disabled={false}
                        action={async () => fetchData()}>
                        {`Применить`}
                    </Button>
                </div>

            </div>

            <Table
                columns={[
                    {
                        title: '№',
                        cell: (item, index) => <>
                            {item.id}
                        </>
                    },
                    {
                        title: 'Администратор',
                        cell: (item, index) => <>
                            <div>{item.manager}</div>
                        </>
                    },
                    {
                        title: 'Дата / Время',
                        cell: (item, index) => <>
                            <div>{moment(item.date).format('DD.MM.YYYY / LT')}</div>
                        </>
                    },
                    {
                        title: 'Действие',
                        cell: (item, index) => <>
                            <div>{item.text}</div>
                        </>
                    },
                    {
                        title: 'Дополнительно',
                        cell: (item, index) => <>
                            <div>{item.rawdata}</div>
                        </>
                    },
                ]}
                data={data}
                dataPerPage={200}
            />


        </div>
    );
}
