import React from "react";
import "./style.scss";

export default function Modal(props) {
    return props.show ? <div className={`ModalWrapperLk`} >
     <div className='modalContentLk'>
                <div className='modalChildrenLk'>{props.children}</div>
            </div>
        <div class="gradient">
           
        </div>
    </div> : null
}
