import React, { useState } from "react";

import "./App.scss";

import MainPage from "./Components/MainPage";
import { BrowserRouter as Router } from "react-router-dom";

import useAuth from "./Hooks/useAuth";
import AuthContext from "./Components/AuthContext";

import ReCaptchaComponent from "./Components/captcha";

import { loadReCaptcha } from 'react-recaptcha-v3'
import config from "./config";
if (config.hostname.includes('https')) loadReCaptcha(config.captcha, () => console.log('loadReCaptcha'));

function AuthProviderWrapper(props) {
  const { authData, setAuthData } = useAuth();
  const [error, setError] = useState(false);

  return <AuthContext.Provider value={{
    authData: authData,
    updateAuthData: setAuthData,
    error: error,
    setError: setError,
  }}>{props.children}</AuthContext.Provider>
}


function App() {
  const [captchaVerify, setCaptchaVerify] = useState(!config.hostname.includes('https'));

  if(window.location.href.includes('clients')){
    return (<div>clients</div>)
  }


  return (
    captchaVerify ?
      <>
        <AuthProviderWrapper>
          <Router>
            <MainPage />
          </Router>
        </AuthProviderWrapper>
      </> : <ReCaptchaComponent onVerify={(value) => setCaptchaVerify(value)} />
  );

}

export default App;
