import React, { useEffect, useState } from "react";

import { Info, Clock } from "../../../../svg";

import Button from "../../../GUI/button";
import Input from "../../../GUI/Input";

import "./style.scss";

import moment from "moment/moment";
import useUpdate from "../../../../Hooks/useUpdate";

export default function Abonement({ data, client, onUpdate }) {

    const [showInfo, setShowInfo] = useState(false);
    const [item, setItem] = useState({});
    const [visitButton, setVisitButton] = useState(true);

    const [freezingDays, setFreezingDays] = useState(7);
    const [freezingDate, setFreezingDate] = useState(moment().format('YYYY-MM-DD'));

    const [newEndDate, setNewEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [newEndDateReason, setNewEndReason] = useState('');

    const [newOwner, setNewOwner] = useState(null);

    const { result, update } = useUpdate();

    useEffect(() => {
        setItem(data);
        if (moment().isAfter(moment(data.date_unfreeze))) {
            console.log('Заморозка истекла');
            update({ type: 'un_freeze_abonement', id: data.id });
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        onUpdate();
        // eslint-disable-next-line
    }, [result]);

    function abonementDateEndCheck(item) {
        if (moment(item.date_end).isSameOrAfter(moment(), 'year') &&
            moment(item.date_end).isSameOrAfter(moment(), 'month') &&
            moment(item.date_end).isSameOrAfter(moment(), 'date')) {
            return true
        } else return false
    }

    function checkAllowedTime() {
        if (!item.time) return true;
        const allowTime = item.time.toString().split('-');
        return allowTime[0] <= moment().hour() && moment().isBefore(moment().set("hour", allowTime[1]).set("minute", 0))
    }

    return (
        <div className="Abonement">

            <div className='AbonementHeader'>
                <div className='AbonementName'>{item.name}</div>
                {!checkAllowedTime() && <div className='AbonementTimeWarning' title="Время посещения не совпадает">
                    <Clock />
                </div>}
                <div className='AbonementShowInfo' onClick={() => setShowInfo(!showInfo)}>
                    <Info />
                </div>
            </div>
            {/* {item.status ? <div className="Status"> {item.status}</div> : null} */}

            {item.date_unfreeze ? <div className="Status">
                {`Абонемент заморожен до ${moment(item.date_unfreeze).format('DD.MM.YYYY')}`}
                <Button
                    action={() => {
                        update({
                            type: 'un_freeze_abonement',
                            value: {

                            },
                            id: item.id,
                            client: client
                        });
                        setFreezingDays(0);
                    }}>
                    {`Разморозить`}
                </Button>
            </div> : null}
            <div className="InfoText">{item.unlim === 1 ? 'Безлимит' : item.visits >= 0 ? `Осталось ${item.visits} посещений` : ''}</div>
            <div className="InfoText">{item.unlim === 0 && <div className="AbonementVisits">
                {visitButton ? <Button
                    style={{ background: 'var(--warning)' }}
                    action={async () => {
                        await update({ type: 'abonement', field: 'visits', value: item.visits - 1, id: item.id });
                        setVisitButton(false);
                    }}>
                    {`Списать посещение`}
                </Button> : <div>Посещение списано</div>}
            </div>}</div>

            {!item.activated && <div className="AbonementEditBottom">
                <Button
                    style={{ background: 'var(--warning)' }}
                    action={() => {
                        update({ type: 'abonement', field: 'activated', value: 1, id: item.id });
                        update({ type: 'abonement', field: 'date_activated', value: moment().format('YYYY.MM.DD'), id: item.id });
                        update({
                            type: 'abonement',
                            field: 'date_end',
                            value: item.duration === 0 ? moment().format('YYYY-MM-DD') : moment().add(item.duration, 'month').format('YYYY-MM-DD'),
                            id: item.id
                        });
                    }}>
                    {`Активировать`}
                </Button>
            </div>}
            {item.date_activated &&
                <div className='AbonementDates'>
                    <div> Дата активации абонемента: </div>
                    <b>{moment(item.date_activated).format('DD MMMM YYYY')} </b>
                </div>
            }
            {item.date_end &&
                <div className='AbonementDates'>
                    <div>Срок действия абонемента:</div>
                    <b>{moment(item.date_end).format('DD MMMM YYYY')}</b>
                </div>
            }

            {(!abonementDateEndCheck(item) && item.activated === 1) &&
                <div style={{ color: 'red', fontWeight: 'bold' }}>Абонемент истек</div>
            }

            {showInfo && <>
                <div className='AbonementData'>
                    <p>Наименование абонемента:<b>{item.name}</b> </p>
                    <p>Место покупки: <b>{item.club}</b></p>
                    <p>Цена: <b>{item.cost}</b></p>
                    <p>Дата продажи абонемента: <b>{moment(item.date_buy).format('DD MMMM YYYY')}</b></p>
                    <p>Длительность: <b>{item.duration} мес.</b></p>
                    <p>Время посещения: <b>{item.time}</b></p>
                    <p>Можно заморозить: <b>{item.canfreeze === 1 ? ' Да ' : ' Нет '}</b></p>
                    {item.canfreeze === 1 && <p>Дней заморозки: <b>{item.freezingdays}</b></p>}
                </div>

                <div className="AbonementEdit">
                    <div className="AbonementEditTitle">Заморозить абонемент</div>
                    <div className="AbonementEditContent">
                        <Input
                            type={'date'}
                            value={freezingDate}
                            onChange={(v) => setFreezingDate(v)} />
                        <Input
                            className={'AbonementEditInput'}
                            type={'number'}
                            min={7}
                            title={"Кол-во. дней"}
                            value={freezingDays}
                            onChange={(v) => (v < 7 || v > item.freezingdays) ? () => { } : setFreezingDays(v)}
                        />

                    </div>
                    <div className="AbonementEditBottom">
                        <Button
                            disabled={item.freezingdays === 0}
                            action={() => {
                                update({
                                    type: 'freeze_abonement',
                                    value: {
                                        freezingDays: freezingDays,
                                        date_unfreeze: moment(freezingDate).add(freezingDays, 'day').format('YYYY-MM-DD'),
                                        //status: `Заморожен с ${moment(freezingDate).format('DD.MM.YYYY')} до ${moment(freezingDate).add(freezingDays, 'day').format('DD.MM.YYYY')}`,
                                        date_end: moment(item.date_end).add(freezingDays, 'day').format('YYYY-MM-DD'),
                                    },
                                    id: item.id,
                                    client: client
                                });
                                setFreezingDays(0);
                            }}>
                            {`Заморозить`}
                        </Button>
                    </div>
                </div>

                <div className="AbonementEdit">
                    <div className="AbonementEditTitle">Изменить срок действия</div>
                    <div className="AbonementEditContent">
                        <Input
                            type={'date'}
                            value={newEndDate}
                            onChange={(v) => setNewEndDate(v)} />
                        <Input
                            className={'AbonementEditInput'}
                            title={"Причина"}
                            value={newEndDateReason}
                            onChange={(v) => setNewEndReason(v)}
                        />

                    </div>
                    <div className="AbonementEditBottom">
                        <Button
                            action={() => {
                                update({ type: 'abonement', field: 'date_end', value: moment(newEndDate).format('YYYY-MM-DD'), id: item.id });

                            }}>
                            {`Применить`}
                        </Button>
                    </div>
                </div>

                <div className="AbonementEdit">
                    <div className="AbonementEditTitle">Переоформить владельца</div>
                    <div className="AbonementEditContent">
                        <Input
                            className={'AbonementEditInput'}
                            title={"id"}
                            value={newOwner}
                            type={'number'}
                            onChange={(v) => setNewOwner(v < 0 ? 0 : v)}
                        />
                    </div>
                    <div className="AbonementEditBottom">
                        <Button
                            action={() => {
                                update({ type: 'new_owner', value: { newOwner: newOwner, abonement: item.name }, id: item.id, client: client });
                                setNewOwner(null);
                            }}>
                            {`Применить`}
                        </Button>
                    </div>
                </div>


            </>

            }

        </div>
    );
}
