import React from 'react';
import './style.scss';

import Button from '../button';

export default class WebcamPicker extends React.Component {
	constructor(props) {
		super(props);

		this.refVideo = React.createRef();
		this.refCanvas = React.createRef();

		this.state = {
			videoRatio: 0,
		}
	}

	async componentDidMount() {
		try {
			if (!navigator.mediaDevices) return;
			//navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then(source => {
			const userMedia = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
			if (userMedia) this.refVideo.current.srcObject = userMedia;
			//this.refVideo.current.srcObject = source;
			this.refVideo.current.onloadedmetadata = (e) => {
				this.refVideo.current.play();
				this.setState({ videoRatio: this.refVideo.current.videoWidth / this.refVideo.current.videoHeight })
			};
			//})
		}
		catch (error) {
			console.error('Не удалось обнаружить веб-камеру');
			console.error(error);
		}
	}

	render() {
		const { videoRatio } = this.state;

		return <div className='WebcamPicker'>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<canvas ref={this.refCanvas} height={200} width={200} style={{ backgroundColor: '#ededed' }} />
				<video ref={this.refVideo} height={200} width={200} style={{ backgroundColor: '#ededed', marginLeft: '20px', marginRight: '10px' }} />
			</div>
			<div className='WebcamControlRow'>
				<Button action={() => {
					this.refCanvas.current.getContext('2d').drawImage(
						this.refVideo.current,
						videoRatio < 1 ? 100 * (1 - videoRatio) : 0,
						videoRatio > 1 ? 100 * (1 - 1 / videoRatio) : 0,
						200,
						200 / videoRatio,
					);
					if (this.props.onSave) {
						this.props.onSave(this.refCanvas.current.toDataURL());
					}
				}}>
					Сделать фото
				</Button>
			</div>
		</div>
	}
}
