import React from "react";

import "./style.scss";
import Dictaphone from "../../GUI/speechrecognition";

export default function Input({
  value,
  onChange = () => { },
  className,
  title,
  type,
  min,
  textarea,
  voice,
  autoFocus,
  flex,
  margin
}) {
  function onChangeInner(event) {
    let value = event.target.value;
    if (type === "number") {
      if (value === "" || value === '' || !value) value = 0;
    }
    onChange(value);
  }

  return (
    <div className="InputRow" style={{ flex: flex, margin: margin }}>
      <div className={type === 'date' ? 'InputDate' : `Input ${className}`}>
        <span>{title}</span>
        {
          !textarea ? <input value={value ?? ""} onChange={onChangeInner} type={type} min={min} autoFocus={autoFocus} />
            :
            <textarea value={value ?? ""} onChange={onChangeInner} type={type} min={min} />
        }
      </div>
      {voice && <Dictaphone onResult={(v) => onChange(v)} />}
    </div>
  );
}
