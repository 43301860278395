import React, { useEffect, useState } from "react";

import Modal from "../../GUI/modal";
import Input from "../../GUI/Input";
import Button from "../../GUI/button";
import Select from "../../GUI/select";

import useUpdate from "../../../Hooks/useUpdate";
import useData from "../../../Hooks/useData";
import AuthContext from "../../AuthContext";

import "./style.scss";

export default function EditClient({ client, showEditMode, onClose, onUpdate, onErr }) {

    const [fio, setFio] = useState('');
    const [card, setCard] = useState('');
    const [phone, setPhone] = useState('+7');
    const [club, setClub] = useState(false);
    const [age, setAge] = useState(null);
    const [passport, setPassport] = useState('');

    const [info, setInfo] = useState('');
    const [clubs, setClubs] = useState([]);

    const authContext = React.useContext(AuthContext);
    const { getData } = useData();
    const { result, update } = useUpdate();

    useEffect(() => {
        if (client) {
            setFio(client.name);
            setCard(client.card);
            setPhone(client.phone);
            setClub(client.club.id);
            setAge(client.age);
            setInfo(client.info);
        }
        // eslint-disable-next-line
    }, [client]);

    useEffect(() => {
        if (result) {
            onUpdate();
            if (`${result}`.includes('Ошибка')) {
                onErr(result);
            }
        }
        // eslint-disable-next-line
    }, [result]);

    useEffect(() => {
        if (authContext.authData.token) {
            getClubs();
        }
        // eslint-disable-next-line
    }, [authContext]);

    async function getClubs() {
        const clubs = await getData({ type: 'selectAll', from: 'club' });
        setClubs(clubs);
    }

    function onSave() {
        if (!club) return;
        if (fio !== client.name) update({ type: 'client', field: 'name', value: fio, id: client.id, client: client });
        if (card !== client.card) update({ type: 'client', field: 'card', value: card, id: client.id, client: client });
        if (phone !== client.phone) update({ type: 'client', field: 'phone', value: phone, id: client.id, client: client });
        if (club !== client.club.id) update({ type: 'client', field: 'clubid', value: (!club || club === '') ? 5 : club, id: client.id, client: client });
        if (age !== client.age) update({ type: 'client', field: 'age', value: age, id: client.id, client: client });
        if (info !== client.info) update({ type: 'client', field: 'info', value: info, id: client.id, client: client });
        if (passport !== client.passport && passport !== '') update({ type: 'client', field: 'passport', value: passport, id: client.id, client: client });
        onClose();
    }

    return (

        <Modal onClose={() => onClose()} show={showEditMode} title={`Рабочий стол - Редактирование - #${client.id} ${client.name}`}>
            <div className="EditClient">

                <Input className={'NewClientInput'} title={"ФИО"} value={fio} onChange={(v) => setFio(v)} />
                <Input className={'NewClientInput'} title={"Номер карты"} value={card} onChange={(v) => setCard(v)} />
                <Input className={'NewClientInput'} title={"Телефон"} value={phone} onChange={(v) => setPhone(v)} />
                <Input className={'NewClientInput'} title={"Паспорт"} value={passport} onChange={(v) => setPassport(v)} />

                <div className={'NewClientClub'}>
                    <Select
                        value={club}
                        title={'Выбрать клуб'}
                        options={(clubs || []).map((item) => {
                            return {
                                value: item.id,
                                label: `${item.name}`,
                            }
                        })}
                        onChange={(v) => setClub(v)}
                    />
                </div>

                <div className={'NewClientAge'}>
                    <div className="NewClientAgeTitle">Дата рождения </div>
                    <Input
                        className={'NewClientInput'}
                        type={'date'}
                        value={age}
                        onChange={(v) => setAge(v)} />
                </div>

                <Input className={'NewClientInput'} textarea={true} title={"Доп. Информация"} value={info} onChange={(v) => setInfo(v)} />

                <div className="EditClientControlRow">
                    <Button
                        style={{ margin: '0 15px 0 0' }}
                        action={() => onClose()}>
                        {`Отменить`}
                    </Button>
                    <Button
                        action={() => onSave()}>
                        {`Сохранить`}
                    </Button>
                </div>
            </div>


        </Modal>

    );
}
