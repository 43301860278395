import React from "react";

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Button from "./GUI/button";
import moment from "moment/moment";

export default function SalesTable({ headers, data }) {

    function onSave(dataToSave) {

        const dataToExport = [];

        const now = `Выгрузка ${moment().format('DD MMMM YYYY LT')}`
        dataToExport.push({ "": now });
        headers.forEach(h => {
            dataToExport.push({ "": h });
        });

        dataToSave.forEach(d => {
            dataToExport.push(d);
        });

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = `data-${moment().format('DD_MM_YYYY_hh_mm')}`
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return <div style={{ margin: '0px 10px 0 10px' }}>
        <Button
            disabled={!data || !data.length}
            action={async () => {
                onSave(data);
            }}>
            {`Скачать XLXS`}
        </Button>
    </div>


}