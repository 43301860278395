import React, { useEffect, useState } from "react";

import { Switch, Route, useLocation, useHistory, NavLink, Redirect } from "react-router-dom";

import { Exit } from "../../svg";

import AuthScreen from "../AuthScreen";
import AuthContext from "../AuthContext";

import Modal from "../GUI/modal";
import Button from "../GUI/button";

import Preloader from "../GUI/preloader";
import moment from "moment/moment";
import 'moment/locale/ru';
import useData from "../../Hooks/useData";
import axios from "axios";
import config from "../../config";
import "./style.scss";

import SearchClient from "../SearchClient";
import NewClient from "../NewClient";
import ExportScreen from "../ExportScreen";
import AbonScreen from "../AbonScreen";
import SalesTypesScreen from "../SalesTypesScreen";
import TrainerTypesScreen from "../TrainerTypesScreen";
import NewSale from "../NewSale";
import Sales from "../SalesScreen";
import ServiceScreen from "../ServiceScreen";
import VisitsScreen from "../VisitsScreen";
import ActionScreen from "../ActionScreen";
import GiftScreen from "../GiftScreen";
import LoginsScreen from "../LoginsScreen";
import PersonalAccount from "./PersonalAccount";

export default function MainPage(props) {
    const authContext = React.useContext(AuthContext);
    const location = useLocation();
    const history = useHistory();

    const [serverStatus, setServerStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [exitConfirm, setExitConfirm] = useState(false);

    const [searchClientHistory, setSearchClientHistory] = useState([]);
    // eslint-disable-next-line
    const { data, setData, dataError, setDataError, getData } = useData();

    async function getServerStatus() {
        const res = await axios.get(`${config.hostname}/getServerStatus`);
        setServerStatus(res.status === 200);
        setInterval(async () => {
            try {
                const res = await axios.get(`${config.hostname}/getServerStatus`);
                setServerStatus(res.status === 200);
            } catch (e) {
                setServerStatus(false);
                console.log(e)
            }
        }, 25000);
    }

    useEffect(() => {
        getServerStatus();
        generate();
    }, []);

    useEffect(() => {
        if (authContext.authData.token) {
            setIsLoading(true);
            getData({ type: 'selectAll', from: 'client' });
            history.push('/Main');
        }
        // eslint-disable-next-line
    }, [authContext]);

    useEffect(() => {
        if (data.length) {
            setIsLoading(false);
        }
    }, [data]);

    function getNavClassName(c) {
        return location.pathname === c ? 'ActiveNavLink' : '';
    }

    function renderContent() {

        if ((`${window.location.href}`.includes('lk'))) {
            return <PersonalAccount />
        } else {
            if (authContext.authData.token) {
                return <div className="MainContent">
               
                    <div className='MainMenu'>
                        <NavLink className={() => getNavClassName('/Main')} to={'/Main'}>Рабочий стол</NavLink>
                        <NavLink className={() => getNavClassName('/NewClient')} to={'/NewClient'}>Новый клиент</NavLink>
                        <NavLink className={() => getNavClassName('/NewSale')} to={'/NewSale'}>Новая продажа</NavLink>
                        <NavLink className={() => getNavClassName('/GiftScreen')} to={'/GiftScreen'}>Подарочные сертификаты</NavLink>
                        <NavLink className={() => getNavClassName('/Abonements')} to={'/Abonements'}>Абонементы</NavLink>
                        <NavLink className={() => getNavClassName('/Store')} to={'/Store'}>Склад</NavLink>
                        <NavLink className={() => getNavClassName('/Trainers')} to={'/Trainers'}>Тренеры</NavLink>
                        <NavLink className={() => getNavClassName('/Sales')} to={'/Sales'}>Продажи</NavLink>
                        <NavLink className={() => getNavClassName('/Actions')} to={'/Actions'}>История действий</NavLink>
                        <NavLink className={() => getNavClassName('/Service')} to={'/Service'}>Сервис</NavLink>
                        <NavLink className={() => getNavClassName('/Export')} to={'/Export'}>Выгрузка</NavLink>
                        <NavLink className={() => getNavClassName('/Visits')} to={'/Visits'}>История посещений</NavLink>
                        <NavLink className={() => getNavClassName('/Logins')} to={'/Logins'}>История авторизаций</NavLink>
                        <div className="MainMenuDelimiter"></div>
                        <div className="CurrentUser">{authContext.authData.user.fullname}</div>
                        <NavLink className={'Exit'} onClick={() => setExitConfirm(true)} to={'/'} >Выход <Exit /></NavLink>
                        <div className="Version">Версия: 0.1.55</div>
                    </div>

                    <div className="MenuContent">
                        <Switch>
                            <Route path={'/Main'}>
                                <SearchClient
                                    data={data}
                                    searchClientHistory={searchClientHistory}
                                    setSearchClientHistory={setSearchClientHistory}
                                    onUpdate={() => {
                                        setIsLoading(true);
                                        getData({ type: 'selectAll', from: 'client' });
                                    }}
                                />
                            </Route>
                            <Route path={'/NewClient'}>
                                <NewClient onCreate={() => {
                                    getData({ type: 'selectAll', from: 'client' });
                                }} />
                            </Route>
                            <Route path={'/NewSale'}>
                                <NewSale />
                            </Route>
                            <Route path={'/GiftScreen'}>
                                <GiftScreen />
                            </Route>
                            <Route path={'/Abonements'}>
                                <AbonScreen />
                            </Route>
                            <Route path={'/Store'}>
                                <SalesTypesScreen />
                            </Route>
                            <Route path={'/Trainers'}>
                                <TrainerTypesScreen />
                            </Route>
                            <Route path={'/Sales'}>
                                <Sales />
                            </Route>
                            <Route path={'/Actions'}>
                                <ActionScreen />
                            </Route>
                            <Route path={'/Service'}>
                                <ServiceScreen />
                            </Route>
                            <Route path={'/Export'}>
                                <ExportScreen />
                            </Route>
                            <Route path={'/Logins'}>
                                <LoginsScreen />
                            </Route>
                            <Route path={'/Visits'}>
                                <VisitsScreen />
                            </Route>
                            <Route path={'/'}>
                                <Redirect to={'/'} />
                            </Route>
                        </Switch>
                    </div>

                </div>
            } else {
                return <AuthScreen />
            }
        }
    }

    function generate() {
        try {
            let hexValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e"];

            function populate(a) {
                for (let i = 0; i < 6; i++) {
                    let x = Math.round(Math.random() * 14);
                    let y = hexValues[x];
                    a += y;
                }
                return a;
            }

            let newColor1 = populate('#');
            let newColor2 = populate('#');
            let angle = Math.round(Math.random() * 360);

            let gradient = "linear-gradient(" + angle + "deg, " + newColor1 + ", " + newColor2 + ")";

            document.getElementById("MainHeader").style.background = gradient;
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="MainPage">

            <div id="MainHeader" className="MainHeader" onClick={() => generate()}>
                {authContext.error ? <div className='NetworkError'>{`${authContext.error}`}</div> : null}
                <div title="Статус сервера" className={`indicator ${serverStatus ? 'on' : 'off'}`}></div>
                <div className="CurrentDateTest">{moment().format('DD MMMM YYYY')}</div>
                {isLoading && <Preloader />}
            </div>

            <Modal onClose={() => setExitConfirm(false)} show={exitConfirm} title='Выйти из учётной записи?'>
                <div className="row">
                    <Button
                        style={{ margin: '18px' }}
                        action={() => {
                            setExitConfirm(false);
                            authContext.updateAuthData({});
                            history.push('/');
                        }}>
                        {`Да`}
                    </Button>
                    <Button
                        style={{ margin: '15px' }}
                        action={() => setExitConfirm(false)}>
                        {`Нет`}
                    </Button>
                </div>
            </Modal>

            {renderContent()}


        </div>
    );
}
