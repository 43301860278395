import React, { useEffect, useState } from "react";

import AuthContext from "../AuthContext";

import Button from "../GUI/button";
import Input from "../GUI/Input";
import Table from "../GUI/table";
import moment from "moment/moment";
import Notification from "../GUI/notification";
import useUpdate from "../../Hooks/useUpdate";

import ClientInfo from "../ClientInfo";

import "./style.scss";

import BarcodeReader from "react-barcode-reader";

export default function SearchClient(props) {
    const context = React.useContext(AuthContext);
    const { result, update } = useUpdate();
    const [clientInfoData, setClientInfoData] = useState(false);

    const [fio, setFio] = useState('');
    const [card, setCard] = useState('');
    const [passport, setPassport] = useState('');
    const [phone, setPhone] = useState('+7');
    const [id, setId] = useState('');

    const [clients, setClients] = useState([]);
    const [foundClientList, setFoundClientList] = useState([]);
    const [notification, setNotification] = useState(false);

    useEffect(() => {
        if (props.data.length) setClients(props.data);
    }, [props.data]);

    useEffect(() => {
        if (props.client) setId(props.client.id);
    }, [props.client]);

    useEffect(() => {
        const foundList = [];
        clients.forEach(client => {
            if (!client.name || !fio) return;
            if (client.name.toLowerCase().includes(fio.toLowerCase())) foundList.push(client);
        });
        setFoundClientList(foundList);
        // eslint-disable-next-line
    }, [fio]);
    useEffect(() => {
        const foundList = [];
        clients.forEach(client => {
            if (!client.phone || !phone) return;
            if (client.phone.toLowerCase().includes(phone.toLowerCase())) foundList.push(client);
        });
        setFoundClientList(foundList);
        // eslint-disable-next-line
    }, [phone]);
    useEffect(() => {
        const foundList = [];
        clients.forEach(client => {
            if (!client.passport || !passport) return;
            if (client.passport.toLowerCase().includes(passport.toLowerCase())) foundList.push(client);
        });
        setFoundClientList(foundList);
        // eslint-disable-next-line
    }, [passport]);
    useEffect(() => {
        const foundList = [];
        clients.forEach(client => {
            if (!client.id || !id) return;
            if (client.id === +id) foundList.push(client);
        });
        setFoundClientList(foundList);
        // eslint-disable-next-line
    }, [id]);

    async function handleClientClick(client) {
        setClientInfoData(client);
        const searchList = [...props.searchClientHistory];
        const found = searchList.find(c => c.id === client.id);
        if (!found) {
            searchList.push(client);
            props.setSearchClientHistory(searchList);
        }
    }

    function renderClientTable(clientList) {
        return <Table
            onRowClick={(v) => handleClientClick(v)}
            columns={[
                {
                    title: '#',
                    cell: (item, index) => <>
                        <div>{item.id}</div>
                    </>
                },
                {
                    title: 'ФИО',
                    cell: (item, index) => <>
                        <div>{item.name}</div>
                    </>
                }
                ,
                {
                    title: 'Телефон',
                    cell: (item, index) => <>
                        <div>{item.phone}</div>
                    </>
                },
                {
                    title: 'Номер карты',
                    cell: (item, index) => <>
                        <div>{item.card}</div>
                    </>
                },
                {
                    title: 'Доп. Инфо',
                    cell: (item, index) => <>
                        <div>{item.info ? item.info : ''}</div>
                    </>
                },
                {
                    title: 'Дата регистрации',
                    cell: (item, index) => <>
                        <div>{item.created_date ? moment(item.created_date).format('DD MMMM YYYY LT') : ''}</div>
                    </>
                },
                {
                    title: 'Дата рождения',
                    cell: (item, index) => <>
                        <div>{item.age ? moment(item.age).format('DD MMMM YYYY') : ''}</div>
                    </>
                }
            ]}
            data={clientList}
            dataPerPage={5}
        />
    }

    return (<>
        <BarcodeReader
            onScan={(data) => {

                if (!clients) return;
                if (!clients.length) return;
                const found = clients.find(client => {
                    if (!client.card) return false;
                    return client.card.toString() === data.toString();
                });
                if (found) {
                    handleClientClick(found);
                } else {
                    setNotification(`Карта не найдена`);
                }

            }}
        />
        {clientInfoData ? <ClientInfo
            clientInfoData={clientInfoData}
            onNewSearch={() => {
                setClientInfoData(false);
                setFio('');
                setCard('');
                setPhone('+7');
                setPassport('');
            }}
            onUpdate={() => props.onUpdate()}
            onBack={() => setClientInfoData(false)}
        />
            :
            <div className="SearchClient">
                <div className="Header">{`Рабочий стол - Поиск клиента - ${clients.length ? `Клиенты: ${clients.length}` : 'Загрузка...'}`}</div>

                <Notification notification={notification} onHide={() => setNotification(false)} />

                <BarcodeReader
                    onScan={(data) => {

                        if (!clients) return;
                        if (!clients.length) return;
                        const found = clients.find(client => {
                            if (!client.card) return false;
                            return client.card.toString() === data.toString();
                        });
                        if (found) {
                            handleClientClick(found);
                        } else {
                            setNotification(`Карта не найдена`);
                        }

                    }}
                />

                {clients.length ? <>

                    <div className="SearchRow">
                        <div className="cardRow">
                            <Input
                                className="SearchInput"
                                title={"Карта"}
                                value={card}
                                onChange={(v) => setCard(v)} />
                            <Button
                                style={{ margin: '10px 10px 0 0' }}
                                action={() => {
                                    const foundList = [];
                                    clients.forEach(client => {
                                        if (!client.card || !card) return;
                                        if (client.card.toLowerCase().includes(card.toLowerCase())) foundList.push(client);
                                    });
                                    setFoundClientList(foundList);
                                }}>
                                {`Поиск по карте`}
                            </Button>
                            <Input
                                className="SearchInput"
                                title={"Паспорт"}
                                value={passport}
                                onChange={(v) => setPassport(v)} />
                        </div>
                    </div>

                    <div className="SearchRow">
                        <Input
                            className="SearchInput"
                            title={"ФИО"}
                            value={fio}
                            onChange={(v) => setFio(v)} />
                        <Input
                            className="SearchInput"
                            title={"Телефон"}
                            value={phone}
                            onChange={(v) => setPhone(v)} />
                        <Input
                            type='number'
                            width={'100px'}
                            className="SearchInputId"
                            title={"id"}
                            value={id}
                            onChange={(v) => setId(v)} />
                    </div>

                    <div className="FoundClientList">
                        {renderClientTable(foundClientList)}
                    </div>

                    <div className="SearchClientHistory">
                        <div className="LastSearch"> Недавние поиски </div>
                        {renderClientTable(props.searchClientHistory)}
                    </div>

                </> : <div></div>}

            </div>}
    </>

    );
}
