import React from "react";
import "./style.scss";

import { Close } from "../../../svg";

export default function Modal(props) {


    return props.show ? <div className={`ModalWrapper`} >
        <div className='modalContent'>
            <div className='modalTitle'>
                {props.title && <div className="titleText">{props.title}</div>}
                <div className='modalClose' onClick={() => props.onClose()}>
                    <Close />
                </div>
            </div>
            <div className='modalChildren'>{props.children}</div>
        </div>
    </div> : null

}
