import React, { useEffect, useState } from "react";

import { Archive, Cancel, Edit } from "../../svg";

import AuthContext from "../AuthContext";

import Button from "../GUI/button";
import Table from '../GUI/table';
import Input from '../GUI/Input';
import Select from "../GUI/select";

import useData from "../../Hooks/useData";
import useUpdate from "../../Hooks/useUpdate";
import useInsert from "../../Hooks/useInsert";
import Modal from "../GUI/modal";

import "./style.scss";

export default function AbonScreen() {
    const authContext = React.useContext(AuthContext);

    const [abonemets, setAbonements] = useState([]);
    const [tab, setTab] = useState(1);

    const { getData } = useData();
    const { result, update } = useUpdate();
    const { insertResult, insert } = useInsert();

    const [clubs, setClubs] = useState([]);

    const [addGood, setAddGood] = useState({ duration: 1, time1: 7, time2: 23, freezingdays: 7, visits: 0 });

    const [addMode, setAddMode] = useState(false);
    const [editGood, setEditGood] = useState(false);

    useEffect(() => {
        if (result || insertResult) {
            setData();
        }
        // eslint-disable-next-line
    }, [result, insertResult]);

    useEffect(() => {
        if (authContext.authData) setData();
        // eslint-disable-next-line
    }, [authContext.authData]);

    async function setData() {
        const abonement = await getData({ type: 'abonement_type', id: authContext.authData.user.clubid });
        const clubs = await getData({ type: 'selectAll', from: 'club' });
        setClubs(clubs);
        setAbonements(abonement.length ? abonement.reverse() : []);
    }

    async function updateAbonementTypes(v) {
        await update({ type: 'abonement_type', field: v.field, value: v.value ? v.value : '', id: v.id });
    }

    function renderAbonements(disabled) {
        return <Table
            columns={[
                {
                    title: '',
                    cell: (item, index) => <>
                        <div title="Редактировать" className="Edit"><Edit onClick={() => {
                            setEditGood(item);
                        }} /></div>
                    </>
                },
                {
                    title: '',
                    cell: (item, index) => <>
                        {item.id}
                    </>,
                    sorter: (a, b) => (a.id - b.id)
                },
                {
                    title: 'Наименование',
                    cell: (item, index) => <>
                        <div>
                            {item.name}
                        </div>
                    </>,
                    sorter: (a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else if (a.name < b.name) {
                            return -1
                        } else {
                            return 0
                        }
                    },
                    filter: (item, value) => item.name.toString().toLowerCase().includes(value.toString().toLowerCase()),
                },
                {
                    title: 'Кол-во посещен.',
                    cell: (item, index) => <>
                        <div>
                            {item.visits}
                        </div>
                    </>
                },
                {
                    title: 'Клуб',
                    cell: (item, index) => <>
                        {item.club}
                    </>,
                    sorter: (a, b) => {
                        if (a.club > b.club) {
                            return 1
                        } else if (a.club < b.club) {
                            return -1
                        } else {
                            return 0
                        }
                    },
                    filter: (item, value) => item.club.toString().toLowerCase().includes(value.toString().toLowerCase()),
                },
                {
                    title: 'Цена (Руб.)',
                    cell: (item, index) => <>
                        {item.cost}
                    </>,
                    sorter: (a, b) => (a.cost - b.cost),
                },
                {
                    title: 'Дл-сть (Мес.)',
                    cell: (item, index) => <>
                        {item.duration}
                        {/* <Input placeholder={''} type="number" value={item.duration} onChange={text => {
                            updateAbonementTypes({ id: item.id, field: 'duration', value: text });
                            switch (text) {
                                case '1':
                                    updateAbonementTypes({ id: item.id, field: 'freezingdays', value: 7 });
                                    break;
                                case '3':
                                    updateAbonementTypes({ id: item.id, field: 'freezingdays', value: 14 });
                                    break;
                                case '6':
                                    updateAbonementTypes({ id: item.id, field: 'freezingdays', value: 30 });
                                    break;
                                case '12':
                                    updateAbonementTypes({ id: item.id, field: 'freezingdays', value: 60 });
                                    break;
                                default:
                                    break;
                            }
                        }} /> */}

                    </>,
                    sorter: (a, b) => (a.duration - b.duration),
                },
                {
                    title: 'Время посещен.',
                    cell: (item, index) => <>
                        {item.time}
                    </>,
                    sorter: (a, b) => (a.time - b.time),
                },
                {
                    title: 'Заморозка (Дн.)',
                    cell: (item, index) => <>
                        {item.freezingdays}
                    </>,
                    sorter: (a, b) => (a.freezingdays - b.freezingdays),
                },
                {
                    title: 'Заморозка',
                    cell: (item, index) => <div className="row">
                        <input type="checkbox" name={'name'} checked={item.canfreeze} value="1" onChange={val => updateAbonementTypes({ id: item.id, field: 'canfreeze', value: !item.canfreeze })} />
                        <div title="В Архив" className="Archive"><Archive onClick={() => {
                            if (disabled) {
                                update({ type: 'abonement_type', field: 'disabled', value: 0, id: item.id });
                            } else {
                                update({ type: 'abonement_type', field: 'disabled', value: 1, id: item.id });
                            }
                        }} /></div>
                    </div>
                },
            ]}
            data={abonemets.filter(a => a.disabled === disabled)}
            dataPerPage={30}
        />
    }

    return (
        <div className="AbonScreen">
            <div className="Header">{`Абонементы`}</div>

            <div className="TypesTabs">
                <div className={`TypesTab ${tab === 1 && 'active'}`} onClick={() => setTab(1)}>Активные</div>
                <div className={`TypesTab ${tab === 2 && 'active'}`} onClick={() => setTab(2)}>Архив</div>
            </div>

            <div className="ControlRow">
                <Button
                    style={{ margin: '5px 0 10px  10px' }}
                    action={() => setAddMode(true)}>{`Добавить`}</Button>
            </div>

            <Modal
                onClose={() => {
                    setAddMode(false);
                    setAddGood({ duration: 1, time1: 7, time2: 23, freezingdays: 7, visits: 0 });
                }}
                show={addMode}
                title='Добавить абонемент'>
                <div className="AddMode">

                    <div className="title">Наименование:</div>
                    <Input placeholder={'Наименование'} value={addGood.name} onChange={text => setAddGood({ ...addGood, name: text })} />

                    <div className="title">{'Кол-во посещен.(0 - безлимит):'}</div>
                    <Input placeholder={'Цена'} min={0} type="number" value={addGood.visits} onChange={text => setAddGood({ ...addGood, visits: text })} />

                    <div className="title">Клуб:</div>
                    <Select
                        value={addGood.club}
                        title={'Выбрать клуб'}
                        options={clubs.map((item) => {
                            return {
                                value: `${item.name}`,
                                label: `${item.name}`,
                            }
                        })}
                        onChange={(v) => setAddGood({ ...addGood, club: v })}
                    />

                    <div className="title">Цена:</div>
                    <Input placeholder={'Цена'} min={0} type="number" value={addGood.cost} onChange={text => setAddGood({ ...addGood, cost: text })} />

                    <div className="title">Длительность (Мес.):</div>
                    <Input
                        placeholder={'Длительность'}
                        min={0}
                        type="number"
                        value={addGood.duration}
                        onChange={text => {
                            
                            switch (text) {
                                case '1':
                                    setAddGood({ ...addGood, duration: text, freezingdays: 7 });
                                    break;
                                case '3':
                                    setAddGood({ ...addGood, duration: text, freezingdays: 14 });
                                    break;
                                case '6':
                                    setAddGood({ ...addGood, duration: text, freezingdays: 30 });
                                    break;
                                case '12':
                                    setAddGood({ ...addGood, duration: text, freezingdays: 60 });
                                    break;
                                default:
                                    setAddGood({ ...addGood, duration: text });
                                    break;
                            }
                        }}

                    />

                    <div className="title">Время посещений от-до:</div>
                    <div className="row">
                        <Input placeholder={'Цена'} min={0} type="number" value={addGood.time1} onChange={text => setAddGood({ ...addGood, time1: text })} />
                        <Input placeholder={'Цена'} min={0} type="number" value={addGood.time2} onChange={text => setAddGood({ ...addGood, time2: text })} />
                    </div>

                    <div className="title">Заморозка:</div>
                    <Input placeholder={'Цена'} min={0} type="number" value={addGood.freezingdays} onChange={text => setAddGood({ ...addGood, freezingdays: text })} />

                    <div className="AddModeControlRow">
                        <Button
                            disabled={!addGood.name || addGood.name === '' || !addGood.club || !addGood.cost}
                            style={{ margin: '5px 0 10px  10px' }}
                            action={() => {
                                insert({
                                    type: 'abonement_types',
                                    value: {
                                        name: addGood.name,
                                        club: addGood.club,
                                        cost: addGood.cost,
                                        duration: addGood.duration,
                                        time: `${addGood.time1}-${addGood.time2}`,
                                        freezingdays: addGood.freezingdays,
                                        visits: addGood.visits,
                                    }
                                });
                                setAddMode(false);
                                setAddGood({ duration: 1, time1: 7, time2: 23, freezingdays: 7, visits: 0 });
                            }}>{`Добавить`}</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                onClose={() => {
                    setEditGood(false);
                }}
                show={editGood}
                title='Редактирование'>
                <div className="AddMode">
                    <div className="title">Наименование:</div>
                    <Input placeholder={'Наименование'} value={editGood.name} onChange={text => setEditGood({ ...editGood, name: text })} />

                    <div className="title">{'Кол-во посещен.(0 - безлимит):'}</div>
                    <Input placeholder={'Цена'} min={0} type="number" value={editGood.visits} onChange={text => setEditGood({ ...editGood, visits: text })} />

                    <div className="title">Цена:</div>
                    <Input placeholder={'Цена'} min={0} type="number" value={editGood.cost} onChange={text => setEditGood({ ...editGood, cost: text })} />

                    <div className="title">Длительность (Мес.):</div>
                    <Input
                        placeholder={'Длительность'}
                        min={0}
                        type="number"
                        value={editGood.duration}
                        onChange={text => {
                          
                            switch (text) {
                                case '1':
                                    setEditGood({ ...editGood, duration: text, freezingdays: 7 });
                                    break;
                                case '3':
                                    setEditGood({ ...editGood, duration: text, freezingdays: 14 });
                                    break;
                                case '6':
                                    setEditGood({ ...editGood, duration: text, freezingdays: 30 });
                                    break;
                                case '12':
                                    setEditGood({ ...editGood, duration: text, freezingdays: 60 });
                                    break;
                                default:
                                    setEditGood({ ...editGood, duration: text });
                                    break;
                            }
                        }}

                    />

                    <div className="title">Заморозка:</div>
                    <Input placeholder={'Цена'} min={0} type="number" value={editGood.freezingdays} onChange={text => setEditGood({ ...editGood, freezingdays: text })} />

                    <div className="AddModeControlRow">
                        <Button
                            disabled={!editGood.name  || !editGood.cost < 0 || editGood.cost === '' || editGood.visits === ''}
                            style={{ margin: '5px 0 10px  10px' }}
                            action={() => {
                                const finded = abonemets.find(item => item.id === editGood.id);
                             
                                if (finded.name !== editGood.name)
                                    updateAbonementTypes({ id: editGood.id, field: 'name', value: editGood.name });
                                if (finded.visits !== editGood.visits)
                                    updateAbonementTypes({ id: editGood.id, field: 'visits', value: editGood.visits });
                                if (finded.cost !== editGood.cost)
                                    updateAbonementTypes({ id: editGood.id, field: 'cost', value: editGood.cost });
                                if (finded.duration !== editGood.duration)
                                    updateAbonementTypes({ id: editGood.id, field: 'duration', value: editGood.duration });
                                if (finded.freezingdays !== editGood.freezingdays)
                                    updateAbonementTypes({ id: editGood.id, field: 'freezingdays', value: editGood.freezingdays });
                                setEditGood(false);
                            }}>{`Применить`}</Button>
                    </div>
                </div>
            </Modal>

            {tab === 1 && renderAbonements(0)}
            {tab === 2 && renderAbonements(1)}

        </div>
    );
}
