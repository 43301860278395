// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import axios from "axios";

import config from "../config";
import AuthContext from "../Components/AuthContext";

export default function useInsert() {
    const context = React.useContext(AuthContext);
    const [insertResult, setResult] = useState(false);
    const [insertError, setError] = useState(false);

    async function insert(params) {
        try {
            setError(false);
            const res = await axios.post(`${config.hostname}/api/Insert`,
                {
                    params: params,
                    token: context.authData.token,
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                    }
                }
            );
            if (res.data.error) {
                setError(res.data.error);
            } else {
                setResult(res.data.data);
            }
        } catch (error) {
            console.log(error);
            setError(error);
            context.setError(error);
        }
    }


    return { insertResult, insert, insertError };
}
