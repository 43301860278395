import React, { Component } from 'react';
import { ReCaptcha } from 'react-recaptcha-v3'
import Preloader from "../GUI/preloader";
import config from '../../config';

class ReCaptchaComponent extends Component {

    verifyCallback = (recaptchaToken) => {
        this.props.onVerify(recaptchaToken ? true : false);
    }

    updateToken = () => {
        this.recaptcha.execute();
    }

    render() {
        return (
            <div>

                <ReCaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={config.captcha}
                    action='action_name'
                    verifyCallback={this.verifyCallback}
                />

                <Preloader/>

            </div>
        );
    };
};

export default ReCaptchaComponent;