// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import axios from "axios";

import config from "../config";
import AuthContext from "../Components/AuthContext";

export default function useAuth() {
    const context = React.useContext(AuthContext);

    const [data, setData] = useState({});
    const [dataError, setDataError] = useState(null);

    function handleError(error) {
        console.log(error, error.response);
        if (error.response && error.response.data) {
            setDataError(`Ошибка ${error.response.data.error ?? error}`);
        } else {
            setDataError(`Ошибка ${error}`);
        }
    }

    async function getData(params) {
        try {
            const res = await axios.post(`${config.hostname}/api/getData`,
                {
                    params: params,
                    token: context.authData.token,
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                    }
                }
            );
            if (res.status === 200) {
                setData(res.data.data);
                return res.data.data;
            } else {
                setDataError(res.data ? res.data.error : `Ошибка ${res.status}`);
                return {}
            }
        } catch (error) {
            handleError(error)
            context.setError(error);
        }
    }


    return { data, setData, dataError, setDataError, getData };
}
