import React from 'react';
import './style.scss';

export default class Chart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ statevalue: this.props.value })
        }
    }

    renderBars() {
        if (!this.props.data || !this.props.data.length) return;
        const maxValue = this.props.data.reduce((acc, val) => {
            return acc > val.value ? acc : val.value;
        });
        const heightCoef = maxValue / 290 ;
        return this.props.data.map((item, index) => <div className='Bar' key={index}>
            <div className='BarValue'>{item.value}</div>
            <div className='BarBand' style={{ height: `${(item.value / heightCoef) / 2}px` }}></div>
            <div className='BarLabel'>{`${item.from}-${item.to}`}</div>
        </div>);
    }

    render() {
        return <div className={`Chart`}>
            <div className={'BarContainer'}>
                {this.renderBars()}
            </div>
        </div>
    }
}