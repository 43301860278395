import React, { useEffect, useState } from "react";

import { Cancel, Edit } from "../../svg";

import AuthContext from "../AuthContext";

import Button from "../GUI/button";
import Table from '../GUI/table';
import Input from '../GUI/Input';

import Select from "../GUI/select";

import useData from "../../Hooks/useData";
import useUpdate from "../../Hooks/useUpdate";
import useInsert from "../../Hooks/useInsert";

import Modal from "../GUI/modal";

import "./style.scss";

export default function SalesTypesScreen() {
    const authContext = React.useContext(AuthContext);

    const [salesTypes, setSalesTypes] = useState([]);
    const [tab, setTab] = useState(1);

    const { getData } = useData();
    const { result, update } = useUpdate();
    const { insertResult, insert } = useInsert();
    const [clubs, setClubs] = useState([]);

    const [addGood, setAddGood] = useState({});

    const [addMode, setAddMode] = useState(false);
    const [editGood, setEditGood] = useState(false);

    useEffect(() => {
        if (result || insertResult) {
            setData();
        }
        // eslint-disable-next-line
    }, [result, insertResult]);

    useEffect(() => {
        if (authContext.authData) setData();

        // eslint-disable-next-line
    }, [authContext.authData]);

    async function setData() {
        const abonement = await getData({ type: 'sales_types', id: authContext.authData.user.clubid });
        getClubs();
        setSalesTypes(abonement.length ? abonement.reverse() : []);
    }

    async function getClubs() {
        const clubs = await getData({ type: 'selectAll', from: 'club' });
        setClubs(clubs);
    }

    async function updateSalesTypes(v) {
        if ((v.field === "cost" || v.field === 'count') && +v.value < 0) return;
        await update({ type: 'sales_types', field: v.field, value: v.value ? v.value : '', id: v.id });
    }

    function renderSales(disabled) {
        return <Table

            columns={[
                {
                    title: '',
                    cell: (item, index) => <>
                        <div title="Редактировать товар" className="Edit"><Edit onClick={() => {
                            setEditGood(item);
                        }} /></div>
                    </>
                },
                {
                    title: '#',
                    cell: (item, index) => <>
                        <div>{item.id}</div>

                    </>,
                    sorter: (a, b) => (a.id - b.id)
                },
                {
                    title: 'Наименование',
                    cell: (item, index) => <>
                        <div>{item.name}</div>
                    </>,
                    sorter: (a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else if (a.name < b.name) {
                            return -1
                        } else {
                            return 0
                        }
                    },
                    filter: (item, value) => value.split(' ').find(val =>
                        item.name.toString().toLowerCase().includes(val.toLowerCase())
                    ),
                },
                {
                    title: 'Клуб',
                    cell: (item, index) => <>
                        <div>{item.clubname}</div>
                    </>,
                    sorter: (a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else if (a.name < b.name) {
                            return -1
                        } else {
                            return 0
                        }
                    },
                    filter: (item, value) => value.split(' ').find(val =>
                        item.clubname.toString().toLowerCase().includes(val.toLowerCase())
                    ),
                },
                {
                    title: 'Цена',
                    cell: (item, index) => <>{item.cost}</>,
                    sorter: (a, b) => (a.cost - b.cost),
                    filter: (item, value) => value.split(' ').find(val =>
                        item.cost.toString().toLowerCase().includes(val.toLowerCase())
                    ),
                },
                {
                    title: 'Кол-во',
                    cell: (item, index) => <>{item.count}</>,
                    sorter: (a, b) => (a.count - b.count),
                },
                {
                    title: 'Сумма',
                    cell: (item, index) => <>
                        <div>{item.cost && item.count ? item.count * item.cost : ''}</div>
                    </>
                },
                {
                    title: '',
                    cell: (item, index) => <>
                        <div title="Удалить товар" className="Archive"><Cancel onClick={() => {

                            if (disabled) {
                                update({ type: 'sales_types', field: 'disabled', value: 0, id: item.id });
                            } else {
                                update({ type: 'sales_types', field: 'disabled', value: 1, id: item.id });
                            }
                        }} /></div>
                    </>
                }
            ]}
            data={salesTypes.filter(a => a.disabled === disabled)}
            dataPerPage={15}
        />
    }

    return (
        <div className="SalesTypes">
            <div className="Header">{`Список товаров`}</div>

            {/* <div className="TypesTabs">
                <div className={`TypesTab ${tab === 1 && 'active'}`} onClick={() => setTab(1)}>Активные</div>
                <div className={`TypesTab ${tab === 2 && 'active'}`} onClick={() => setTab(2)}>Архив</div>
            </div>  */}

            <Modal
                onClose={() => {
                    setAddMode(false);
                    setAddGood({});
                }}
                show={addMode}
                title='Добавить товар'>
                <div className="AddMode">
                    <div className="title">Клуб:</div>
                    <Select
                        value={addGood.club}
                        title={'Выбрать клуб'}
                        options={clubs.map((item) => {
                            return {
                                value: item.id,
                                label: `${item.name}`,
                            }
                        })}
                        onChange={(v) => setAddGood({ ...addGood, club: v })}
                    />
                    <div className="title">Наименование:</div>
                    <Input placeholder={'Наименование'} value={addGood.name} onChange={text => setAddGood({ ...addGood, name: text })} />
                    <div className="title">Цена:</div>
                    <Input placeholder={'Цена'} type="number" value={addGood.cost} onChange={text => setAddGood({ ...addGood, cost: text })} />
                    <div className="title">Кол-во:</div>
                    <Input placeholder={'Кол-во'} type="number" value={addGood.count} onChange={text => setAddGood({ ...addGood, count: text })} />
                    <div className="AddModeControlRow">
                        <Button
                            disabled={!addGood.club || addGood.club === 5 || !addGood.count || !addGood.cost || !addGood.count < 0 || !addGood.cost < 0 || addGood.cost === '' || addGood.count === ''}
                            style={{ margin: '5px 0 10px  10px' }}
                            action={() => {
                                insert({
                                    type: 'sales_types',
                                    value: {
                                        id: addGood.club,
                                        name: addGood.name,
                                        cost: addGood.cost,
                                        count: addGood.count,
                                    }
                                });
                                setAddGood({ club: addGood.club });
                            }}>{`Добавить`}</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                onClose={() => {
                    setEditGood(false);
                }}
                show={editGood}
                title='Редактирование / Оприходование '>
                <div className="AddMode">
                    <div className="title">Наименование:</div>
                    <Input placeholder={'Наименование'} value={editGood.name} onChange={text => setEditGood({ ...editGood, name: text })} />
                    <div className="title">Цена:</div>
                    <Input placeholder={'Цена'} type="number" value={editGood.cost} onChange={text => setEditGood({ ...editGood, cost: text })} />
                    <div className="title">Кол-во:</div>
                    <Input placeholder={'Кол-во'} type="number" value={editGood.count} onChange={text => setEditGood({ ...editGood, count: text })} />
                    <div className="AddModeControlRow">
                        <Button
                            disabled={!editGood.count || !editGood.cost || !editGood.count < 0 || !editGood.cost < 0 || editGood.cost === '' || editGood.count === ''}
                            style={{ margin: '5px 0 10px  10px' }}
                            action={() => {
                                const finded = salesTypes.find(item => item.id === editGood.id);
                                if (finded.name !== editGood.name)
                                    updateSalesTypes({ id: editGood.id, field: 'name', value: editGood.name });
                                if (finded.cost !== editGood.cost)
                                    updateSalesTypes({ id: editGood.id, field: 'cost', value: editGood.cost });
                                if (finded.count !== editGood.count)
                                    updateSalesTypes({ id: editGood.id, field: 'count', value: editGood.count });
                                setEditGood(false);
                            }}>{`Применить`}</Button>
                    </div>
                </div>
            </Modal>

            <div className="ControlRow">
                <Button
                    style={{ margin: '5px 0 10px  10px' }}
                    action={() => setAddMode(true)}>{`Добавить товар`}</Button>
            </div>

            {tab === 1 && renderSales(0)}
            {tab === 2 && renderSales(1)}

        </div>
    );
}
