import React, { useEffect, useState } from "react";
import "./style.scss";

import Button from "../../GUI/button";
import Input from "../../GUI/Input";
import Select from "../../GUI/select";

import moment from "moment/moment";
import Abonement from "./Abonement";
import ServiceSelect from "./ServiceSelect";
import AuthContext from "../../AuthContext";
import useData from "../../../Hooks/useData";
import useUpdate from "../../../Hooks/useUpdate";
import useInsert from "../../../Hooks/useInsert";

import Notification from "../../GUI/notification";

export default function AbonementInfo({ client, onUpdate }) {

    const context = React.useContext(AuthContext);

    const [showExpired, setShowExpired] = useState(false);

    const [showSelectSales, setSelectSales] = useState(false);
    const [showSelectTrainer, setSelectTrainer] = useState(false);

    const [newClientAbonement, setNewClientAbonement] = useState(false);
    const [newClientDateBuy, setNewClientDateBuy] = useState(moment().format('YYYY-MM-DD'));
    const [newClientDateEnd, setNewClientDateEnd] = useState(moment().format('YYYY-MM-DD'));

    const [gifts, setGifts] = useState([]);
    const [newClientGift, setNewClientGift] = useState(false);

    const [saleSearch, setSaleSearch] = useState('');
    const [filteredSales, setFilteredSales] = useState([]);
    // eslint-disable-next-line
    const [newDeposit, setNewDeposit] = useState(0);
    const [newTrainer, setNewTrainer] = useState(false);
    const [newSale, setNewSale] = useState(false);
    const [newSaleAmount, setNewSaleAmount] = useState(1);
    const [pay, setPay] = useState(false);

    const { getData } = useData();
    // eslint-disable-next-line
    const { result, update } = useUpdate();
    const { insertResult, insert } = useInsert();

    const [abonements, setAbonements] = useState([]);
    const [sales, setSales] = useState([]);
    const [trainers, setTrainers] = useState([]);

    const [notification, setNotification] = useState(false);

    useEffect(() => {
        if (client) setData();
        // eslint-disable-next-line
    }, [client]);

    useEffect(() => {
        if (result || insertResult) {
            onUpdate();
            setNotification(`Успешно`);
        }
        // eslint-disable-next-line
    }, [result, insertResult]);

    useEffect(() => {
        setFilteredSales(sales.filter(s => `${s.name}`.toLowerCase().includes(`${saleSearch}`.toLowerCase())))
        // eslint-disable-next-line
    }, [saleSearch]);

    async function setData() {
        try {
            const clubId = context.authData.user.clubid;
            const abonRes = await getData({ type: 'abonements', id: client.club.id });
            const salesRes = await getData({ type: 'select_sales_types', from: 'sales_types', conditionName: 'clubid', condition: clubId === 4 ? false : clubId });
            const trainerRes = await getData({ type: 'selectAll', from: 'trainer_types', conditionName: 'clubid', condition: clubId === 4 ? false : clubId });
            const salesGifts = await getData({ type: 'gifts' });
            setAbonements(abonRes.filter(item => !item.disabled));
            setSales(salesRes);
            setTrainers(trainerRes);
            setGifts(salesGifts.filter(a => !a.owner));
        } catch (e) {
            console.log(e);
        }
    }

    function renderAbonements() {

        if (client.abonement.length && client.abonement.length > 50) {
            const slisedAbonements = client.abonement.slice(-5);
            return slisedAbonements.map((item, index) => { // eslint-disable-line
                return <Abonement key={index}
                    data={item}
                    client={client}
                    onUpdate={() => onUpdate()}
                />
            })
        } else {
            if (!client.abonement?.length) {
                return <div style={{ color: 'red', fontWeight: 'bold' }}>Абонемент отсутствует</div>
            } else {
                return client.abonement.map((item, index) => { // eslint-disable-line
                    if (showExpired) {
                        return <Abonement key={index}
                            data={item}
                            client={client}
                            onUpdate={() => onUpdate()}
                        />
                    } else {
                        if (item.activated === 0 || (moment(item.date_end).isSameOrAfter(moment(), 'year') && moment(item.date_end).isSameOrAfter(moment(), 'month') && moment(item.date_end).isSameOrAfter(moment(), 'date'))) {
                            return <Abonement key={index}
                                data={item}
                                client={client}
                                onUpdate={() => onUpdate()}
                            />
                        }
                    }
                })
            }
        }


    }

    function onAbonementSelect(v) {
        const abon = abonements.find(item => item.id == v);
        setNewClientAbonement(abon);
        if (!(abon.activation === '30') && !(moment(abon.activation).isValid())) {
            console.log('У абонемента не правильно указан срок активации')
        }
        if (abon.duration !== 0) {
            setNewClientDateEnd(moment(newClientDateBuy).add(abon.duration, 'month').format('YYYY-MM-DD'));
        } else {
            setNewClientDateEnd(moment(newClientDateBuy).add(1, 'day').format('YYYY-MM-DD'));
        }
    }

    function onGiftSelect(v) {
        const abon = gifts.find(item => item.id == v);
        setNewClientGift(abon);
        if (!(abon.activation === '30') && !(moment(abon.activation).isValid())) {
            console.log('У абонемента не правильно указан срок активации')
        }
        if (abon.duration !== 0) {
            setNewClientDateEnd(moment(newClientDateBuy).add(abon.duration, 'month').format('YYYY-MM-DD'));
        } else {
            setNewClientDateEnd(moment(newClientDateBuy).add(1, 'day').format('YYYY-MM-DD'));
        }
    }

    return (
        <div className="AbonementInfo">
            <Notification notification={notification} onHide={() => setNotification(false)} />

            <div className="AbonementInfoBlock">
                <div className="Card">
                    <div className="Title">Абонементы</div>
                    <div className="showExpired" onClick={() => setShowExpired(!showExpired)}>
                        <div className="GlobalEditButton">{showExpired ? 'Скрыть истекшие абонементы' : `Показать все абонементы (${client.abonement?.length})`}</div>
                    </div>
                    {renderAbonements()}
                </div>
                <div className="Card">
                    <div className="Title">Внести депозит</div>
                    <div className="CardItemSpace">
                        <Input
                            className={'newSaleAmount'}
                            type={'number'}
                            min={1}
                            title={"Депозит"}
                            value={newDeposit}
                            onChange={(v) => { }}
                        />
                    </div>
                    <div className="ControlRow">
                        <Button
                            useConfirm={true}
                            disabled={true}
                            action={() => {

                            }}>
                            {`Продажа`}
                        </Button>
                    </div>
                </div>
            </div>

            <div className="AbonementInfoBlock">

                <div className="Card">
                    <div className="Title">Активные персональные занятия</div>
                    {client.trainingname && <div className="CardItem">{client.trainingname}</div>}
                    {client.trainingname && <div className="CardItem">Колличество доступных тренировок: <b>{client.trainingcount}</b></div>}
                    <div className="ControlRow">
                        <Button
                            useConfirm={true}
                            style={{ margin: '0 15px 0 0' }}
                            disabled={!client.discardtraining}
                            action={async () => await insert({
                                type: 'discard_training',
                                value: {
                                    id: client.id,
                                },
                            })}>
                            {`Возврат тренировки`}
                        </Button>
                        <Button
                            useConfirm={true}
                            disabled={!client.trainingcount > 0}
                            action={async () => await insert({
                                type: 'sub_training',
                                value: {
                                    id: client.id,
                                },
                            })}>
                            {`Списать тренировку`}
                        </Button>
                    </div>
                </div>

                <div className="Card">
                    <div className="Title">Продажа нового абонемента</div>
                    <div className="ServiceSelect">
                        <div className="newClientAbonement">
                            {`${newClientAbonement.name || ''} ${newClientAbonement.cost ? `Цена: ${newClientAbonement.cost}` : ''}`}
                        </div>
                        <Select
                            value={newClientAbonement.id}
                            title={'Наименование'}
                            options={abonements.filter(item => item.name !== '0').map((item) => {
                                return {
                                    value: item.id,
                                    label: `${item.name} - ${item.cost}р. ${client.club.id === 5 ? item.club : ''}`,
                                }
                            })}
                            onChange={(v) => onAbonementSelect(v)}
                        />
                    </div>
                    <div className="CardItem">
                        <Select isPayment={true} value={pay} onChange={(v) => setPay(v)} />
                    </div>
                    <div className="CardItemSpace">
                        <div>Дата начала действия абонемента</div>
                        <Input
                            type={'date'}
                            value={newClientDateBuy}
                            onChange={(v) => setNewClientDateBuy(v)} />
                    </div>
                    <div className="CardItemSpace">
                        <div>Дата окончания действия абонемента</div>
                        <b>{moment(newClientDateEnd).format('DD.MM.YYYY')}</b>
                    </div>
                    <div className="ControlRow">
                        <Button
                            disabled={!pay || !newClientAbonement}
                            action={async () => {
                                await insert({
                                    type: 'abonement',
                                    value: {
                                        data: newClientAbonement,
                                        owner: client,
                                        date_buy: newClientDateBuy,
                                        pay: pay,
                                        user: context.authData.user,
                                        type: newClientAbonement.id,
                                        need_activation: moment().add(30, 'days').format('YYYY-MM-DD'),
                                    }
                                });
                                setNewClientAbonement(false);
                            }}>
                            {`Продажа`}
                        </Button>
                    </div>
                </div>

                <div className="Card">
                    <div className="Title">Подарочный сертификат</div>
                    <div className="ServiceSelect">
                        <div className="newClientAbonement">
                            {`${newClientGift.name || ''} ${newClientGift.cost ? `Цена: ${newClientGift.cost}` : ''}`}
                        </div>
                        <Select
                            value={newClientGift.id}
                            title={'Наименование'}
                            options={gifts.map((item) => {
                                return {
                                    value: item.id,
                                    label: `№${item.id} - ${item.name} - ${item.cost}р. ${item.club}`,
                                }
                            })}
                            onChange={(v) => onGiftSelect(v)}
                        />
                    </div>
                    <div className="CardItemSpace">
                        <div>Дата начала действия абонемента</div>
                        <b>{moment(newClientDateBuy).format('DD.MM.YYYY')}</b>
                    </div>
                    <div className="CardItemSpace">
                        <div>Дата окончания действия абонемента</div>
                        <b>{moment(newClientDateEnd).format('DD.MM.YYYY')}</b>
                    </div>
                    <div className="ControlRow">
                        <Button
                            disabled={!newClientGift}
                            action={async () => {
                                await insert({
                                    type: 'gift',
                                    value: {
                                        data: newClientGift,
                                        owner: client,
                                        date_buy: newClientDateBuy,
                                        date_end: newClientDateEnd,
                                        user: context.authData.user,
                                        need_activation: moment().add(30, 'days').format('YYYY-MM-DD'),
                                    }
                                });
                                setNewClientGift(false);
                            }}>
                            {`Применить`}
                        </Button>
                    </div>
                </div>

                <div className="Card">
                    <div className="Title">Персональная тренировка</div>
                    <div className="CardItem">
                        <Select
                            value={newTrainer}
                            title={'Наименование'}
                            options={trainers.map((item) => {
                                return {
                                    value: item.id,
                                    label: `${item.trainer} - ${item.name}(${item.hours}) - ${item.cost}р.`,
                                }
                            })}
                            onChange={(v) => setNewTrainer(v)}
                        />
                    </div>
                    <div className="CardItem">
                        <Select isPayment={true} value={pay} onChange={(v) => setPay(v)} />
                    </div>
                    <div className="ControlRow">
                        <Button
                            disabled={!pay || !newTrainer}
                            action={async () => {
                                await insert({
                                    type: 'add_training',
                                    value: {
                                        id: newTrainer,
                                        owner: client,
                                        user: context.authData.user,
                                        pay: pay,
                                    },
                                });
                                setNewTrainer(false);
                            }}>
                            {`Продажа`}
                        </Button>
                    </div>
                </div>

                <div className="Card">
                    <div className="Title">Добавить покупку</div>
                    <Input
                        className={'saleSearch'}
                        title={"Поиск"}
                        value={saleSearch}
                        onChange={(v) => setSaleSearch(v ? v : '')}
                    />
                    <div className="CardItemSpace">
                        <div></div>
                        <Input
                            className={'newSaleAmount'}
                            type={'number'}
                            min={1}
                            title={"Кол-во."}
                            value={newSaleAmount}
                            onChange={(v) => v <= 0 ? () => { } : setNewSaleAmount(v)}
                        />
                    </div>

                    <div className="CardItem">
                        <Select
                            value={newSale}
                            title={'Наименование'}
                            options={(filteredSales.length ? filteredSales : sales).map((item) => {
                                return {
                                    value: item.id,
                                    label: `${item.name} - ${item.cost}р. (${item.count} шт.)`,
                                }
                            })}
                            onChange={(v) => setNewSale(v)}
                        />
                    </div>
                    <div className="CardItem">
                        <Select isPayment={true} value={pay} onChange={(v) => setPay(v)} />
                    </div>
                    <div className="ControlRow">
                        <Button
                            disabled={!newSale || !pay}
                            action={async () => {
                                await insert({
                                    type: 'sale',
                                    value: {
                                        id: newSale,
                                        owner: client,
                                        pay: pay,
                                        user: context.authData.user,
                                        amount: newSaleAmount
                                    }
                                });
                                setNewSale(false);
                                setNewSaleAmount(1);
                            }}>
                            {`Продажа`}
                        </Button>
                    </div>
                </div>


            </div>


        </div>
    );
}
