import React, { useEffect, useState } from "react";
import "./style.scss";

import Button from "../GUI/button";
import Input from "../GUI/Input";
import Select from "../GUI/select";
import Checkbox from "../GUI/checkbox";

import moment from "moment/moment";

import AuthContext from "../AuthContext";
import useData from "../../Hooks/useData";
import useUpdate from "../../Hooks/useUpdate";
import useInsert from "../../Hooks/useInsert";

import SalesTable from "./renderSalesTable";
import ExcelExport from "../ExcelExport";

import Notification from "../GUI/notification";

export default function Sales() {

    const context = React.useContext(AuthContext);

    const [sales, setSales] = useState([]);
    const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().add(1, 'day').format('YYYY-MM-DD'));

    const { getData } = useData();
    const { result, update } = useUpdate();
    // eslint-disable-next-line
    const { insertResult, insert } = useInsert();

    const [notification, setNotification] = useState(false);
    const [showCancels, setShowCancels] = useState(false);

    const [summa, setSumma] = useState(0);
    const [cash, setCash] = useState(0);
    const [cashless, setCashless] = useState(0);
    const [deposit, setDeposit] = useState(0);

    const [adminFilter, setAdminFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [clubFilter, setClubFilter] = useState(false);
    const [payFilter, setPayFilter] = useState(false);
    const [typeFilter, setTypeFilter] = useState(false);
    const [clientFilter, setClientFilter] = useState('');
    const [trainerFilter, setTrainerFilter] = useState('');
    // eslint-disable-next-line
    const [salesTypes, setSalesTypes] = useState([{ name: 'Продажа' }, { name: 'Услуга' }, { name: 'Абонемент' }]);
    const [clubs, setClubs] = useState([]);

    useEffect(() => {
        setData();
        getClubs();
        // eslint-disable-next-line
    }, [context.authData]);

    useEffect(() => {
        if (result || insertResult) {
            setNotification(`Успешно`);
            setData();
        }
        // eslint-disable-next-line
    }, [result, insertResult]);

    async function getClubs() {
        try {
            const clubs = await getData({ type: 'selectAll', from: 'club' });
            setClubs(clubs);
        } catch (e) {
            console.log(e);
            setNotification(`${e}`);
        }
    }

    async function setData() {
        try {
            let sum = 0;
            let cash = 0;
            let cashless = 0;
            let deposit = 0;
            let salesList = await getData({ type: 'sales', from: dateFrom, to: dateTo, isCanceled: showCancels });

            if (nameFilter !== '') {
                const filtered = [];
                salesList.forEach(element => {
                    if (`${element.name}`.toLowerCase().includes(nameFilter.toLowerCase())) filtered.push(element);
                });
                salesList = filtered;
            }

            if (clientFilter !== '') {
                const filtered = [];
                salesList.forEach(element => {
                    if (`${element.client}`.toLowerCase().includes(clientFilter.toLowerCase())) filtered.push(element);
                });
                salesList = filtered;
            }

            if (adminFilter !== '') {
                const filtered = [];
                salesList.forEach(element => {
                    if (`${element.admin}`.toLowerCase().includes(adminFilter.toLowerCase())) filtered.push(element);
                });
                salesList = filtered;
            }

            if (trainerFilter !== '') {
                const filtered = [];
                salesList.forEach(element => {
                    if (`${element.trainer}`.toLowerCase().includes(trainerFilter.toLowerCase())) filtered.push(element);
                });
                salesList = filtered;
            }

            if (clubFilter) {
                const filtered = [];
                salesList.forEach(element => {
                    if (`${element.clubid}`.includes(clubFilter)) filtered.push(element);
                });
                salesList = filtered;
            }

            if (typeFilter) {
                const filtered = [];
                salesList.forEach(element => {
                    if (`${element.type}`.toLowerCase().includes(typeFilter.toLowerCase())) filtered.push(element);
                });
                salesList = filtered;
            }

            if (payFilter) {
                const filtered = [];
                salesList.forEach(element => {
                    if (`${element.pay}`.toLowerCase().includes(payFilter.toLowerCase())) filtered.push(element);
                });
                salesList = filtered;
            }

            salesList.forEach(element => {
                switch (element.pay) {
                    case 'Наличные':
                        cash += element.cost * element.amount;
                        sum += element.cost * element.amount;
                        break;

                    case 'Карта':
                        cashless += element.cost * element.amount;
                        sum += element.cost * element.amount;
                        break;

                    case 'Депозит':
                        deposit += element.cost * element.amount;
                        break;
                    default: break;
                }
            });

            setCash(cash);
            setCashless(cashless);
            setDeposit(deposit);
            setSumma(sum);

            setSales(salesList.reverse());
        } catch (e) {
            console.log(e);
            setNotification(`${e}`);
        }
    }

    function getExportData(sales) {
        const ret = [];
        sales.forEach((s) =>
            ret.push({
                'Администратор': s.admin,
                'Наименование': s.name,
                'Дата': moment(s.date).format('DD.MM.YYYY LT'),
                'Цена': s.cost,
                'Кол-во': s.amount,
                'Способ оплаты': s.pay,
                'Тип': s.type,
                'Клиент': s.client,
                'Тренер': s.trainer,
            }));
        return ret;
    }

    return (
        <div className="Sales">
            <div className="Header">{showCancels ? `Продажи - Отмены` : `Продажи`}</div>
            <Notification notification={notification} onHide={() => setNotification(false)} />

            <div className="SalesControlRow">

                <Checkbox value={showCancels} onSwitch={async () => {
                    setShowCancels(!showCancels);
                    await setData();
                }}>Показать отмены</Checkbox>
                <ExcelExport
                    data={getExportData(sales)}
                    headers={[
                        `Период: ${moment(dateFrom).format('DD.MM.YYYY')} - ${moment(dateTo).format('DD.MM.YYYY')} `,
                        `Наличные: ${cash} ₽`,
                        `Безналичные: ${cashless} ₽`,
                        `Всего: ${summa} ₽`]}

                />
            </div>

            <div className="SalesHeader">
                <div className="SalesFilter">

                    <div className="SalesFilterRow">
                        <div className="SalesFilterItem">
                            <span>Дата от:</span>
                            <Input
                                type={'date'}
                                value={dateFrom}
                                onChange={(v) => setDateFrom(v)} />
                        </div>
                        <div className="SalesFilterItem">
                            <span>Дата до:</span>
                            <Input
                                type={'date'}
                                value={dateTo}
                                onChange={(v) => setDateTo(v)} />
                        </div>
                    </div>

                    <div className="SalesFilterRow">
                        <Input flex={1} title={'Наименование'} value={nameFilter} onChange={v => setNameFilter(v)} />
                    </div>

                    <div className="SalesFilterRow">
                        <Input flex={1} title={'Клиент'} value={clientFilter} onChange={v => setClientFilter(v)} />
                    </div>

                    <div className="SalesFilterRow">
                        <Input flex={1} title={'Администратор'} value={adminFilter} onChange={v => setAdminFilter(v)} />
                    </div>

                    <div className="SalesFilterRow">
                        <Input flex={1} title={'Тренер'} value={trainerFilter} onChange={v => setTrainerFilter(v)} />
                    </div>

                    <div className="SalesFilterRow">
                        <Select
                            value={clubFilter}
                            title={'Выбрать клуб'}
                            options={clubs.map((item) => {
                                return {
                                    value: item.id,
                                    label: `${item.name}`,
                                }
                            })}
                            onChange={(v) => setClubFilter(v)}
                        />
                    </div>

                    <div className="SalesFilterRow">
                        <Select isPayment={true} value={payFilter} onChange={(v) => setPayFilter(v)} />
                        <Select
                            value={typeFilter}
                            title={'Выбрать тип'}
                            options={salesTypes.map((item) => {
                                return {
                                    value: item.name,
                                    label: item.name,
                                }
                            })}
                            onChange={(v) => setTypeFilter(v)}
                        />
                    </div>

                    <div className="FilterControl">
                        <Button
                            disabled={false}
                            action={async () => {
                                setDateFrom(moment().format('YYYY-MM-DD'));
                                setDateTo(moment().add(1, 'day').format('YYYY-MM-DD'));
                                setAdminFilter('');
                                setNameFilter('');
                                setPayFilter(false);
                                setTypeFilter(false);
                                setClubFilter(false);
                                setClientFilter('');
                                setTrainerFilter('');
                                setData();
                            }}>
                            {`Сбросить`}
                        </Button>
                        <Button
                            disabled={false}
                            action={async () => {
                                setData();
                            }}>
                            {`Применить`}
                        </Button>
                    </div>
                </div>

                <div className="SalesInfo">
                    <div className="SalesInfoItem">
                        <div className="SalesInfoLabel">Наличные:</div>
                        {cash} ₽
                    </div>
                    <div className="SalesInfoItem">
                        <div className="SalesInfoLabel">Безналичные:</div>
                        {cashless} ₽
                    </div>
                    <div className="SalesInfoItem">
                        <div className="SalesInfoLabel">Депозит:</div>
                        {deposit} ₽
                    </div>
                    <div className="SalesInfoItem">
                        <div className="SalesInfoLabel">Всего:</div>
                        {summa} ₽
                    </div>
                </div>

            </div>

            <SalesTable data={sales} onSaleCancel={(saleId) => update({ type: 'sales', field: 'iscanceled', value: 1, id: saleId })} />

        </div>
    );
}
