import React, { useEffect, useState } from "react";

import AuthContext from "../AuthContext";

import Button from "../GUI/button";
import Table from '../GUI/table';
import Input from '../GUI/Input';

import Select from "../GUI/select";

import useData from "../../Hooks/useData";
import useUpdate from "../../Hooks/useUpdate";
import useInsert from "../../Hooks/useInsert";


import "./style.scss";

export default function TrainerTypesScreen() {
    const authContext = React.useContext(AuthContext);

    const [trainerTypes, setTrainerTypes] = useState([]);
    // eslint-disable-next-line
    const [tab, setTab] = useState(1);

    const { getData } = useData();
    const { result, update } = useUpdate();
    const { insertResult, insert } = useInsert();
    const [clubs, setClubs] = useState([]);
    const [club, setClub] = useState(5);

    useEffect(() => {
        if (result || insertResult) {
            setData();
        }
        // eslint-disable-next-line
    }, [result, insertResult]);

    useEffect(() => {
        if (authContext.authData) setData();
        console.log(authContext)
        // eslint-disable-next-line
    }, [authContext.authData]);

    async function setData() {
        const data = await getData({ type: 'trainer_types', id: authContext.authData.user.clubid });
        getClubs();
        setTrainerTypes(data.length ? data.reverse() : []);
    }

    async function getClubs() {
        const clubs = await getData({ type: 'selectAll', from: 'club' });
        setClubs(clubs);
    }

    async function updateTrainerTypes(v) {
        if((v.field === "cost" || v.field === 'hours') && +v.value < 0) return;
        await update({ type: 'trainer_types', field: v.field, value: v.value ? v.value : '', id: v.id });
    }

    function renderTrainer(disabled) {
        return  <Table
        columns={[
            {
                title: 'ID',
                cell: (item, index) => <>
                   <div>{item.id}</div> 
                    
                </>,
                sorter: (a, b) => (a.id - b.id)
            },
            {
                title: 'Тренер',
                cell: (item, index) => <>
                   
                        <Input placeholder={''} value={item.trainer} onChange={text => updateTrainerTypes({ id: item.id, field: 'trainer', value: text })} />
                    
                </>,
                filter: (item, value) => value.split(' ').find(val =>
                    item.trainer.toString().toLowerCase().includes(val.toLowerCase())
                ),
            },
            {
                title: 'Наименование',
                cell: (item, index) => <>
                <div>
                   
                        <Input placeholder={'Наименование'} value={item.name} onChange={text => updateTrainerTypes({ id: item.id, field: 'name', value: text })} />
                    
                    </div>
                </>,
                sorter: (a, b) => {
                    if (a.name > b.name) {
                        return 1
                    } else if (a.name < b.name) {
                        return -1
                    } else {
                        return 0
                    }
                },
                filter: (item, value) => value.split(' ').find(val =>
                    item.name.toString().toLowerCase().includes(val.toLowerCase())
                ),
            },
            {
                title: 'Тренировки (кол.во)',
                cell: (item, index) => <>
                <div style={{maxWidth:'100px'}}>
                        <Input placeholder={''} type="number" value={item.hours} onChange={text => updateTrainerTypes({ id: item.id, field: 'hours', value: text })} />
                    </div>
                </>,
                sorter: (a, b) => (a.cost - b.cost)
            },
            {
                title: 'Клуб',
                cell: (item, index) => <>
                    <div>{item.clubname}</div>
                </>,
                sorter: (a, b) => {
                    if (a.name > b.name) {
                        return 1
                    } else if (a.name < b.name) {
                        return -1
                    } else {
                        return 0
                    }
                },
                filter: (item, value) => value.split(' ').find(val =>
                    item.clubname.toString().toLowerCase().includes(val.toLowerCase())
                ),
            }
            ,
            {
                title: 'Цена - руб.',
                cell: (item, index) => <>
                        <Input placeholder={''} type="number" value={item.cost} onChange={text => updateTrainerTypes({ id: item.id, field: 'cost', value: text })} />
                </>,
                sorter: (a, b) => (a.cost - b.cost),
                filter: (item, value) => value.split(' ').find(val =>
                    item.cost.toString().toLowerCase().includes(val.toLowerCase())
                ),
            }

        ]}
        data={trainerTypes}
        dataPerPage={15}
    />
    }

    return (
        <div className="TrainerScreen">
            <div className="Header">{`Список товаров`}</div>

            {/* <div className="TypesTabs">
                <div className={`TypesTab ${tab === 1 && 'active'}`} onClick={() => setTab(1)}>Активные</div>
                <div className={`TypesTab ${tab === 2 && 'active'}`} onClick={() => setTab(2)}>Архив</div>
            </div>  */}

            <div className="ControlRow">
                <div className={'ClubSelect'}>
                    <Select
                        value={club}
                        title={'Выбрать клуб'}
                        options={clubs.map((item) => {
                            return {
                                value: item.id,
                                label: `${item.name}`,
                            }
                        })}
                        onChange={(v) => setClub(v)}
                    />
                </div>
                <Button
                    disabled={club === 5}
                    style={{ margin: '5px 0 10px  10px' }}
                    action={() => insert({
                        type: 'trainer_types',
                        value: {
                            id: club,
                        }
                    })}>{`Добавить`}</Button>
            </div>

            {tab === 1 && renderTrainer(0)}
            {/* {tab === 2 && renderTrainer(1)} */}

        </div>
    );
}
