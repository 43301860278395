import React, { useEffect, useState } from "react";
import "./style.scss";

import Button from "../GUI/button";
import Input from "../GUI/Input";
import Select from "../GUI/select";

import AuthContext from "../AuthContext";
import useData from "../../Hooks/useData";
import useUpdate from "../../Hooks/useUpdate";
import useInsert from "../../Hooks/useInsert";

import Notification from "../GUI/notification";

export default function NewSale() {

    const context = React.useContext(AuthContext);

    const [saleSearch, setSaleSearch] = useState('');
    const [filteredSales, setFilteredSales] = useState([]);

    const [newSale, setNewSale] = useState(false);
    const [newSaleAmount, setNewSaleAmount] = useState(1);
    const [pay, setPay] = useState(false);

    const { getData } = useData();
    // eslint-disable-next-line
    const { result, update } = useUpdate();
    const { insertResult, insert } = useInsert();

    const [sales, setSales] = useState([]);

    const [notification, setNotification] = useState(false);

    useEffect(() => {
        setData();
        // eslint-disable-next-line
    }, [context.authData]);

    useEffect(() => {
        if (result || insertResult) {
            setNotification(`Успешно`);
        }
        // eslint-disable-next-line
    }, [result, insertResult]);

    useEffect(() => {
        setFilteredSales(sales.filter(s => `${s.name}`.toLowerCase().includes(`${saleSearch}`.toLowerCase())))
        // eslint-disable-next-line
    }, [saleSearch]);

    async function setData() {
        try {
            const clubId = context.authData.user.clubid;
            const salesRes = await getData({ type: 'select_sales_types', from: 'sales_types', conditionName: 'clubid', condition: clubId === 4 ? false : clubId });
            setSales(salesRes);
        } catch (e) {
            console.log(e);
        }
    }



    return (
        <div className="NewSale">
            <div className="Header">{`Продажа`}</div>
            <Notification notification={notification} onHide={() => setNotification(false)} />

            <div className="Card">
                <div className="Title">Добавить покупку</div>
                <Input
                    className={'saleSearch'}
                    title={"Поиск"}
                    value={saleSearch}
                    onChange={(v) => setSaleSearch(v ? v : '')}
                />
                <div className="CardItemSpace">
                    <div>В наличии: </div>
                    <Input
                        margin={'0 0 10px 0'}
                        className={'newSaleAmount'}
                        type={'number'}
                        min={1}
                        title={"Кол-во."}
                        value={newSaleAmount}
                        onChange={(v) => v <= 0 ? () => { } : setNewSaleAmount(v)}
                    />
                </div>

                <div className="CardItem">
                    <Select
                        value={newSale}
                        title={'Наименование'}
                        options={(filteredSales.length ? filteredSales : sales).map((item) => {
                            return {
                                value: item.id,
                                label: `${item.name} - ${item.cost}р. (${item.count} шт.)`,
                            }
                        })}
                        onChange={(v) => setNewSale(v)}
                    />
                </div>
                <div className="CardItem">
                    <Select isPayment={true} value={pay} onChange={(v) => setPay(v)} />
                </div>
                <div className="ControlRow">
                    <Button
                        disabled={!newSale || !pay}
                        action={async () => {
                            await insert({
                                type: 'sale',
                                value: {
                                    id: newSale,
                                    owner: null,
                                    pay: pay,
                                    user: context.authData.user,
                                    amount: newSaleAmount
                                }
                            });
                            setNewSale(false);
                            setNewSaleAmount(1);
                        }}>
                        {`Продажа`}
                    </Button>
                </div>
            </div>

        </div>
    );
}
