import React, { useEffect, useState } from "react";
import "./style.scss";

import AuthContext from "../AuthContext";
import useData from "../../Hooks/useData";
import moment from "moment/moment";

import Input from "../GUI/Input";
import Button from "../GUI/button";
import Table from "../GUI/table";
import Chart from '../GUI/chart';
import Preloader from "../GUI/preloader";

export default function LoginsScreen() {

    const context = React.useContext(AuthContext);

    const { getData } = useData();
    const [logins, setLogins] = useState([]);
    const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().add(1, 'day').format('YYYY-MM-DD'));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setData();
        // eslint-disable-next-line
    }, [context.authData]);

    async function setData() {
        try {
            setIsLoading(true);
            const res = await getData({ type: 'logins_history', from: dateFrom, to: dateTo });
            setLogins(res);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="LoginsScreen">

            <div className="VisitsFilter">
                <div className="VisitsFilterRow">
                    <div className="VisitsFilterItem">
                        <span>Дата от:</span>
                        <Input
                            type={'date'}
                            value={dateFrom}
                            onChange={(v) => setDateFrom(v)} />
                    </div>
                    <div className="VisitsFilterItem">
                        <span>Дата до:</span>
                        <Input
                            type={'date'}
                            value={dateTo}
                            onChange={(v) => setDateTo(v)} />
                    </div>
                </div>

                <div className="VisitsFilterRow">
                    <span></span>
                    <Button
                        style={{ margin: '10px 0 0 0' }}
                        disabled={false}
                        action={async () => setData()}>
                        {`Применить`}
                    </Button>
                </div>
            </div>

            <Table
                columns={[
                    {
                        title: '№',
                        cell: (item, index) => <>
                            {item.id}
                        </>
                    },
                    {
                        title: 'Логин',
                        cell: (item, index) => <>
                            <div>{item.login}</div>
                        </>
                    },
                    {
                        title: 'ФИО',
                        cell: (item, index) => <>
                            <div>{item.fullname}</div>
                        </>
                    },
                    {
                        title: 'ip',
                        cell: (item, index) => <>
                            <div>{item.ip}</div>
                        </>,

                    },
                    {
                        title: 'Agent',
                        cell: (item, index) => <>
                            <div>{item.agent}</div>
                        </>,

                    },
                    {
                        title: 'Время',
                        cell: (item, index) => <>
                            <div>{moment(item.date).format('LT')}</div>
                        </>,

                    },
                    {
                        title: 'Дата',
                        cell: (item, index) => <>
                            <div>{moment(item.date).format('DD.MM.YYYY')}</div>
                        </>,
                    },
                ]}
                data={logins}
                dataPerPage={40}
            />


        </div>
    );
}
