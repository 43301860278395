import React, { useState, useEffect } from "react";

import "./style.scss";
import { Ok, Warning } from "../../../svg";

export default function Notification({ notification, onHide }) {

    const [show, setShow] = useState(false);

    useEffect(() => {
        notification ? setShow(true) : setShow(false);
        if (!notification) return;
        const timerId = setTimeout(() => onHide(), 4 * 1000);
        return () => {
            clearTimeout(timerId);
        };
        // eslint-disable-next-line
    }, [notification]);

    return (
        (notification && notification.error) ?
            <div className={`Notification`}>
                <Warning />
                <span>{notification.error}</span>
            </div>
            :
            (show && notification) ? <div className={`Notification`}>
                {notification === 'Успешно' && <Ok />}
                <span>{notification}</span>
            </div> : <></>
    );
}
