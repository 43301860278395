import React, { useEffect, useState } from "react";

import { Image, Cake, In, Out } from "../../svg";
import config from '../../config';

import AuthContext from "../AuthContext";

import Modal from "../GUI/modal";
import Button from "../GUI/button";

import moment from "moment/moment";
import WebcamPicker from "../GUI/webcampicker";

import useData from "../../Hooks/useData";
import useUpdate from "../../Hooks/useUpdate";

import EditClient from "./EditClient";
import AbonementInfo from "./AbonementInfo";
import Notification from "../GUI/notification";

import ClientSales from "./ClientSales";
import ClientVisits from "./ClientVisits";

import "./style.scss";

export default function ClientInfo(props) {

    const context = React.useContext(AuthContext);

    const [editMode, setEditMode] = useState(false);
    const [client, setClient] = useState(false);
    const [tab, setTab] = useState(1);
    const [showWebcam, setShowWebcam] = useState(false);

    const { getData } = useData();
    const { result, update } = useUpdate();

    const [canEnterIndicator, setCanEnterIndicator] = useState(null);

    const [notification, setNotification] = useState(false);

    useEffect(() => {
        if (result) setData();
        // eslint-disable-next-line
    }, [result]);

    useEffect(() => {
        if (props.clientInfoData) setData();
        // eslint-disable-next-line
    }, [props.clientInfoData]);

    async function setData() {
        const clientGet = await getData({ type: 'getClientById', id: props.clientInfoData.id })
        setClient(clientGet);
        checkCanClientEnter(clientGet);
    }

    // async function onClientIn() {
    //     const abonement = client.abonement;
    //     if (!abonement || abonement.length === 0) setNotification({ error: 'У клиента нет абонемента' });
    //     if (abonement) {
    //         const activatedAbonement = abonement.find((ab) => ab.activated === 1 && moment(ab.date_end).isSameOrAfter(moment()));
    //         if (activatedAbonement) {
    //             if (activatedAbonement.unlim === 0 && activatedAbonement.visits > 0) {
    //                 await update({ type: 'abonement', field: 'visits', value: activatedAbonement.visits - 1, id: activatedAbonement.id });
    //                 setNotification(`Списано посещение абонемента: ${activatedAbonement.name}`);
    //             }
    //         } else {
    //             setNotification({ error: 'Нет активного абонемента' });
    //         }
    //     }
    // }

    async function checkCanClientEnter(c) {
        const abonement = c.abonement;
        if (!abonement || abonement.length === 0) {
            setCanEnterIndicator('У клиента нет абонемента');
            return;
        }
        setCanEnterIndicator(null);
    }

    return (
        <div className="ClientInfo">

            <Notification notification={notification} onHide={() => setNotification(false)} />

            <EditClient
                client={client}
                showEditMode={editMode}
                onUpdate={() => {
                    setData();
                    props.onUpdate();
                }}
                onClose={() => setEditMode(false)}
                onErr={(err) => setNotification(err)}
            />

            <div className="Header">
                <div>{`Рабочий стол - Клиент - #${client.id} ${client.name} - `}</div>
                <div className="Edit" onClick={() => {
                    setEditMode(true);
                }}>Редактировать</div>
                <div className="Edit" onClick={() => {
                    update({ type: 'client_create_pass', admin: context.authData.user.id, id: client.id });
                }}>Создать \ Заменить пароль</div>
                <div className="Pass">{client.pass}</div>
            </div>

            <div className="ControlRow">
                <Button style={{ margin: '15px 0 0 10px' }} action={() => props.onBack()}>{`Назад`}</Button>
                <Button style={{ margin: '15px 10px 0 0' }} action={() => props.onNewSearch()}>{`Новый поиск`}</Button>
            </div>

            <div className="GlobalTabs">
                <div className={`tab ${tab === 1 && 'active'}`} onClick={() => setTab(1)}>Информация</div>
                <div className={`tab ${tab === 2 && 'active'}`} onClick={() => setTab(2)}>История посещений</div>
                <div className={`tab ${tab === 3 && 'active'}`} onClick={() => setTab(3)}>Покупки</div>
            </div>

            <Modal onClose={() => setShowWebcam(false)} show={showWebcam} title='Редактирование фото клиента'>
                <WebcamPicker onSave={b64 => {
                    update({ type: 'client', field: 'photo', value: b64, id: client.id });
                    setShowWebcam(false);
                }} />
            </Modal>

            {tab === 1 && <div className="Client">
                {client ? <>
                    <div className="ClientContainer">
                        <div className='ClientPhoto'>
                            {client.photo ?
                                <img src={`${config.hostname}/file/${client.photo}`} alt='фото' onClick={() => setShowWebcam(true)} />
                                :
                                <div className="NoImage" onClick={() => setShowWebcam(true)}>
                                    <Image />
                                    <div>Нет фото</div>
                                    <div>Нажмите чтобы сделать</div>
                                </div>
                            }
                        </div>
                        <div className="ClientData">
                            <div className="ClientDataRow">
                                <div className="title">{`#${client.id} ${client.name}`}</div>
                                {client.age ? (new Date(client.age).getMonth() === new Date().getMonth() && new Date(client.age).getDate() === new Date().getDate()) &&
                                    <div className="Cake">
                                        <Cake />
                                    </div> : null
                                }
                            </div>
                            <div className="ClientDataRow">
                                <div className="title">Паспорт</div>
                                <div className="value">{client.passport ? client.passport : '-'}</div>
                            </div>
                            <div className="ClientDataRow">
                                <div className="title">Дата рождения</div>
                                <div className="value">{client.age ? moment(client.age).format('DD MMMM YYYY') : ''}</div>
                            </div>
                            <div className="ClientDataRow">
                                <div className="title">Телефон</div>
                                <div className="value">{client.phone ? client.phone : '-'}</div>
                            </div>

                            <div className="ClientDataRow">
                                <div className="title">Клуб</div>
                                <div className="value">{client.club ? client.club.name : '-'}</div>
                            </div>
                            <div className="ClientDataRow">
                                <div className="title">Номер карты</div>
                                <div className="value">{client.card ? client.card : '-'}</div>
                            </div>

                            <div className="ClientDataRow">
                                <div className="title">Депозит</div>
                                <div className="value">{client.deposit ? client.deposit : '-'}</div>
                            </div>

                            <div className="ClientDataRow">
                                <div className="title">Администратор</div>
                                <div className="value">{client.manager ? client.manager.fullname : '-'}</div>
                            </div>

                            <div className="ClientDataRow">
                                <div className="title">Первая тренировка</div>
                                <div className="value">
                                    <div>{client.introductory ? '0' : '1'}</div>
                                    {client.introductory ? null :
                                        <div className="Edit" onClick={() => {
                                            update({ type: 'client', field: 'introductory', value: 1, id: client.id });
                                        }}>Списать первую тренировку</div>}
                                </div>
                            </div>

                            <div className="ClientInfoRow">
                                <div className="title">Доп. информация</div>
                                <div className="value info">{client.info ? client.info : '-'}</div>
                            </div>
                        </div>

                    </div>

                    <div className="VisitsRow">
                        <div className="CanEnterIndicator ">
                            {canEnterIndicator}
                        </div>
                        <div className="VisitsRowDelimiter"></div>
                        <div className="VisitIndicator">
                            {client.last_visit ?
                                <div className={`EnterIndicator ${moment().format('DD.MM.YYYY') === moment(client.last_visit).format('DD.MM.YYYY') ? 'today' : ''}`}>
                                    Посещение зафиксировано {moment(client.last_visit).format('DD.MM.YYYY LT')}
                                    {moment().format('DD.MM.YYYY') === moment(client.last_visit).format('DD.MM.YYYY') ? ' (Сегодня) ' : null}
                                </div> : null}
                            {client.last_visit ? <In /> : null}
                        </div>
                        <Button style={{ margin: '0 10px 0 0' }} action={async () => {
                            //onClientIn();
                            await update({ type: 'client_in', admin: context.authData.user.id, id: client.id })
                        }}>{`Вход`}</Button>
                        <Button action={async () => {
                            await update({ type: 'client_out', admin: context.authData.user.id, id: client.id });
                            props.onNewSearch();
                        }}>{`Выход`}</Button>
                    </div>

                    <AbonementInfo client={client} onUpdate={() => setData()} />

                </> : <div className="loading">Загрузка...</div>}
            </div>}

            {tab === 2 && <div>
                <ClientVisits
                    tab={tab}
                    id={client.id}
                    onIn={async () => await update({ type: 'client_in', admin: context.authData.user.id, id: client.id })}
                    onExit={async () => await update({ type: 'client_out', admin: context.authData.user.id, id: client.id })}
                    result={result}
                />
            </div>}

            {tab === 3 && <div>
                <ClientSales tab={tab} id={client.id} />
            </div>}

        </div>
    );
}
