import React, { useEffect, useState } from "react";
import "./style.scss";

import Button from "../GUI/button";
import Input from "../GUI/Input";
import Select from "../GUI/select";
import Table from "../GUI/table";
import { Cancel } from "../../svg";


import AuthContext from "../AuthContext";
import useData from "../../Hooks/useData";
import useUpdate from "../../Hooks/useUpdate";
import useInsert from "../../Hooks/useInsert";

import Notification from "../GUI/notification";

export default function ServiceScreen() {

    const context = React.useContext(AuthContext);

    const { getData } = useData();
    const { result, update } = useUpdate();
    const { insertResult, insert } = useInsert();
    const [clubs, setClubs] = useState([]);
    const [users, setUsers] = useState([]);
    const [notification, setNotification] = useState(false);

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [fullname, setFullname] = useState('');
    const [clubid, setClubid] = useState(false);

    useEffect(() => {
        setData();
        // eslint-disable-next-line
    }, [context.authData]);

    useEffect(() => {
        if (result || insertResult) {
            setNotification(`Успешно`);
            setData();
            setLogin('');
            setPassword('');
            setPhone('');
            setFullname('');
        }
        // eslint-disable-next-line
    }, [result, insertResult]);

    async function setData() {
        try {
            const res = await getData({ type: 'users' });
            setUsers(res);
            const clubs = await getData({ type: 'selectAll', from: 'club' });
            setClubs(clubs);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        context.authData.user.clubid !== 4 ? <div className="GlobalHeader">Нет доступа</div> :
            <div className="ServiceScreen">
                <div className="Header">{`Сервис - Пользователи`}</div>
                <Notification notification={notification} onHide={() => setNotification(false)} />

                <div className="Card">
                    <div className="CardItem">
                        <Input flex={1} title={'Логин'} value={login} onChange={v => setLogin(v)} />
                    </div>
                    <div className="CardItem">
                        <Input flex={1} title={'Пароль'} type='password' value={password} onChange={v => setPassword(v)} />
                    </div>
                    <div className="CardItem">
                        <Input flex={1} title={'ФИО'} value={fullname} onChange={v => setFullname(v)} />
                    </div>
                    <div className="CardItem">
                        <Input flex={1} title={'Телефон'} value={phone} onChange={v => setPhone(v)} />
                    </div>

                    <div className="CardItem">
                        <Select
                            value={clubid}
                            title={'Выбрать клуб'}
                            options={clubs.map((item) => {
                                return {
                                    value: item.id,
                                    label: `${item.name}`,
                                }
                            })}
                            onChange={(v) => setClubid(v)}
                        />
                    </div>

                    <div className="ControlRow">
                        <Button
                            disabled={login === '' || password === '' || fullname === '' || !clubid}
                            action={async () => {
                                await insert({
                                    type: 'user',
                                    value: {
                                        login,
                                        password,
                                        fullname,
                                        phone,
                                        clubid,
                                    }
                                });

                            }}>
                            {`Создать`}
                        </Button>
                    </div>
                </div>


                <Table
                    columns={[
                        {
                            title: '',
                            cell: (item, index) =>
                                item.clubid !== 4 && <div className='BanUser'
                                    title='Заблокировать'
                                    onClick={async () => {
                                        await update({ type: 'user', field: 'isblocked', value: 1, id: item.id });
                                    }}>
                                    <Cancel />
                                </div>
                        },
                        {
                            title: '№',
                            cell: (item, index) => <>
                                <div>{item.id}</div>
                            </>
                        },
                        {
                            title: 'Логин',
                            cell: (item, index) => <>
                                <div>{item.login}</div>
                            </>
                        },
                        {
                            title: 'ФИО',
                            cell: (item, index) => <>
                                <div>{item.fullname}</div>
                            </>
                        },
                        {
                            title: 'Телефон',
                            cell: (item, index) => <>
                                <div>{item.phone}</div>
                            </>
                        },
                        {
                            title: 'Клуб',
                            cell: (item, index) => <>
                                <div>{item.clubname}</div>
                            </>
                        },
                    ]}
                    data={users}
                    dataPerPage={50}
                />


            </div>
    );
}
