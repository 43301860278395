import React, { useEffect, useState } from "react";

import AuthContext from "../AuthContext";

import Button from "../GUI/button";
import Input from "../GUI/Input";
import Checkbox from "../GUI/checkbox";
import Select from "../GUI/select";

import WebcamPicker from "../GUI/webcampicker";

import useData from "../../Hooks/useData";
import useInsert from "../../Hooks/useInsert";
import Notification from "../GUI/notification";

import "./style.scss";

export default function NewClient({ onCreate }) {
    const [fio, setFio] = useState('');
    const [card, setCard] = useState('');
    const [phone, setPhone] = useState('+7');
    const [club, setClub] = useState(5);
    const [age, setAge] = useState(null);
    const [gender, setGender] = useState(0);
    const [info, setInfo] = useState('');
    const [photo, setPhoto] = useState(null);
    const [passport, setPassport] = useState('')
    const [noCard, setNoCard] = useState(false);

    const [clubs, setClubs] = useState([]);

    const authContext = React.useContext(AuthContext);
    const { getData } = useData();
    const { insertResult, insert, insertError } = useInsert();
    const [notification, setNotification] = useState(false);

    useEffect(() => {
        if (insertResult) {
            setFio('');
            setPhone('+7');
            setInfo('');
            setNotification(`Успешно`);
            onCreate();
        }
        // eslint-disable-next-line
    }, [insertResult]);

    useEffect(() => {
        if (authContext.authData.token) {
            getClubs();
        }
        // eslint-disable-next-line
    }, [authContext]);

    async function getClubs() {
        const clubs = await getData({ type: 'selectAll', from: 'club' });
        setClubs(clubs);
    }

    return (
        <div className="NewClient">
            <div className="Header">{`Новый клиент`}</div>
            <Notification notification={notification} onHide={() => setNotification(false)} />
            <div className="NewClientCard">
                <div className="NewClientWebcamPicker">
                    <WebcamPicker onSave={b64 => setPhoto(b64)} />
                </div>

                <Input className={'NewClientInput'} title={"*ФИО"} value={fio} onChange={(v) => setFio(v)} />
                {noCard ? <></> : <Input className={'NewClientInput'} title={"Номер карты"} value={card} onChange={(v) => setCard(v)} />}
                <div className="NewClientGender">
                    <div></div>
                    <Checkbox value={noCard} onSwitch={() => {
                        setCard('');
                        setNoCard(!noCard);
                    }}>Нет карты</Checkbox>
                </div>
                <Input className={'NewClientInput'} title={"*Телефон"} value={phone} onChange={(v) => setPhone(v)} />
                <Input className={'NewClientInput'} title={"*Паспорт"} value={passport} onChange={(v) => setPassport(v)} />

                <div className={'NewClientClub'}>
                    <Select
                        value={club}
                        title={'Выбрать клуб'}
                        options={clubs.map((item) => {
                            return {
                                value: item.id,
                                label: `${item.name}`,
                            }
                        })}
                        onChange={(v) => setClub(v)}
                    />
                </div>

                <div className={'NewClientAge'}>
                    <div className="NewClientAgeTitle">Дата рождения </div>
                    <Input
                        className={'NewClientInput'}
                        type={'date'}
                        value={age}
                        onChange={(v) => setAge(v)} />
                </div>

                <div className="NewClientGender">
                    <Checkbox value={!gender} onSwitch={() => setGender(0)}>Мужчина</Checkbox>
                    <Checkbox value={gender} onSwitch={() => setGender(1)}>Женщина</Checkbox>
                </div>

                <Input className={'NewClientInput'} title={"Доп. Информация"} value={info} onChange={(v) => setInfo(v)} />

                <div className="NewClientControlRow">
                    <Button
                        disabled={fio === '' || phone === '+7' || !club || passport === ''}
                        action={async () => {
                            await insert({
                                type: 'client',
                                value: {
                                    fio,
                                    card: card === '' ? null : card,
                                    phone,
                                    age: age === '' ? null : age,
                                    gender,
                                    info,
                                    photo,
                                    clubid: club,
                                    user: authContext.authData.user,
                                    passport,
                                },
                            });
                        }}>
                        {`Создать`}
                    </Button>
                </div>
                {insertError && <div className="GlobalError">{insertError}</div>}
            </div>
        </div>
    );
}
