import React, { useEffect, useState } from "react";

import AuthContext from "../AuthContext";

import Button from "../GUI/button";
import Input from "../GUI/Input";
import Checkbox from "../GUI/checkbox";

import Modal from "../GUI/modal";
import moment from "moment/moment";
import useData from "../../Hooks/useData";
import useUpdate from "../../Hooks/useUpdate";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import "./style.scss";

import useService from "../../Hooks/useService";
import renderClientTable from "./renderClientTable";

export default function ServiceScreen({ handleClientClick }) {
    const context = React.useContext(AuthContext);
    const authContext = React.useContext(AuthContext);
    const { getData } = useData();
    const { getExportResult, getExport } = useService();
    // eslint-disable-next-line
    const { result, update } = useUpdate();
    // eslint-disable-next-line
    const [clubs, setClubs] = useState([]);

    const [useComment, setUseComment] = useState(false);
    const [usePeriod, setUsePeriod] = useState(false);

    const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
    const [comment, setComment] = useState('');
    const [client, setClient] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [error, setError] = useState(null);

    const [exportData, setExportData] = useState([]);

    useEffect(() => {
        if (authContext.authData.token) {
            getClubs();
        }
        // eslint-disable-next-line
    }, [authContext]);

    useEffect(() => {
        if (result) {

        }
        if (getExportResult) {
            setExportData(getExportResult);
        }
        // eslint-disable-next-line
    }, [result, getExportResult]);

    async function getClubs() {
        const clubs = await getData({ type: 'selectAll', from: 'club' });
        const admins = await getData({ type: 'selectAll', from: 'user' });
        setClubs(clubs);
        setAdmins(admins);
    }

    return ( context.authData.user.clubid !== 4 ? <div className="GlobalHeader">Нет доступа</div> :
        <div className="ServiceScreen">
            <div className="Header">{`Выгрузка`}</div>

            <div className="ExportCard">

                <div className="ExportFilter">
                    <div className="ExportFilterTitle">
                        <Checkbox value={usePeriod} onSwitch={() => {
                            setUseComment(false);
                            setUsePeriod(true);
                        }
                        }>Период окончания действия абонемента</Checkbox>
                    </div>
                    <div className="ExportFilterRow">
                        <Input
                            type={'date'}
                            value={dateFrom}
                            onChange={(v) => setDateFrom(v)} />
                        <Input
                            type={'date'}
                            value={dateTo}
                            onChange={(v) => setDateTo(v)} />
                    </div>
                </div>

                <div className="ExportFilter">
                    <div className="ExportFilterTitle">
                        <Checkbox value={useComment} onSwitch={() => {
                            setUseComment(true);
                            setUsePeriod(false);
                        }}>По комментарию</Checkbox>
                    </div>

                    <Input
                        className={'ExportFilterInput'}
                        title={"Комментарий"}
                        value={comment}
                        onChange={(v) => setComment(v)}
                    />

                </div>

                <div className="ExportControl">
                    <Button
                        disabled={!useComment && !usePeriod}
                        action={async () => {
                            if (usePeriod) await getExport({ filter: 'period', value: { dateFrom, dateTo } });
                            if (useComment) await getExport({ filter: 'comment', value: { comment } });
                        }}>
                        {`Применить`}
                    </Button>
                </div>
            </div>

            <div className="Header">{`Выгрузка - Результат - Всего: ${exportData.length ? exportData.length : 'Нет данных'}`}</div>

            <div className="ExportDownload">
                <div className="ExportError">{error}</div>
                <Button
                    disabled={!exportData.length}
                    action={async () => {
                        try {
                            exportData.forEach(client => {
                                if (client.manager) {
                                    const found = admins.find((admin) => admin.id === client.manager);
                                    client.managerName = found ? found.fullname : '';
                                }
                            });
                            const dataToExport = [];
                            exportData.forEach(client => {
                                dataToExport.push({
                                    Номер: client.id,
                                    ФИО: client.name,
                                    Клуб: client.club ? client.club : '',
                                    Телефон: client.phone,
                                    Карта: client.card,
                                    Пол: client.gender === 0 ? 'Муж.' : 'Жен.',
                                    Возраст: client.age ? moment().diff(moment(client.age), 'year') : '',
                                    Абонемент: client.abon,
                                    Дата_покупки: moment(client.date_buy).format('DD.MM.YYYY'),
                                    Дата_активаци: moment(client.date_activated).format('DD.MM.YYYY'),
                                    Дата_окончания: moment(client.date_end).format('DD.MM.YYYY'),
                                    Персональная_тренировка: client.trainingname ? client.trainingname : '',
                                    Доп_инфо: client.info ? client.info : '',
                                    Дата_рождения: client.age ? moment(client.age).format('DD.MM.YYYY') : '',
                                    Администратор: client.managerName
                                });
                            });

                            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                            const fileExtension = '.xlsx';
                            const fileName = `Clients-${moment().format('DD_MM_YYYY_hh_mm')}`
                            const ws = XLSX.utils.json_to_sheet(dataToExport);
                            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: fileType });
                            FileSaver.saveAs(data, fileName + fileExtension);
                        }
                        catch (e) {
                            console.log(e);
                            setError(`${e}`);
                        }
                    }}>
                    {`Скачать`}
                </Button>
            </div>

            {renderClientTable(exportData, (v) => setClient(v))}
            <Modal onClose={() => setClient(false)} show={client} title={client.name}>
                <div className="ExportClient">
                    {`${JSON.stringify(client)}`}
                </div>
            </Modal>
        </div>
    );
}
