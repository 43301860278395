// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import axios from "axios";

import config from "../config";
import AuthContext from "../Components/AuthContext";

export default function useUpdate() {
    const context = React.useContext(AuthContext);
    const [result, setResult] = useState(false);

    async function update(params) {
        try {
            setResult(false);
            const res = await axios.post(`${config.hostname}/api/Update`,
                {
                    params: params,
                    token: context.authData.token,
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                    }
                }
            );
            setResult(res.data.data);
        } catch (error) {
            console.log(error);
            context.setError(error);
        }
    }


    return { result, setResult, update };
}
