import React from "react";
import Table from '../GUI/table';
import moment from "moment/moment";

export default function renderClientTable(data, handleClientClick) {
    return <Table
        onRowClick={(v) => handleClientClick(v)}
        columns={[
            {
                title: '#',
                cell: (item, index) => <>
                    <div>{item.id}</div>
                </>
            },
            {
                title: 'ФИО',
                cell: (item, index) => <>
                    <div>{item.name}</div>
                </>
            }
            ,
            {
                title: 'Телефон',
                cell: (item, index) => <>
                    <div>{item.phone}</div>
                </>
            },
            {
                title: 'Номер карты',
                cell: (item, index) => <>
                    <div>{item.card}</div>
                </>
            },
            {
                title: 'Доп. Инфо',
                cell: (item, index) => <>
                    <div>{item.info ? item.info : ''}</div>
                </>
            },
            {
                title: 'Абонемент',
                cell: (item, index) => <>
                    <div>{item.abon}</div>
                </>
            },
            {
                title: 'Абон. покупка',
                cell: (item, index) => <>
                    <div>{item.date_buy ? moment(item.date_buy).format('DD.MM.YYYY') : ''}</div>
                </>
            },
            {
                title: 'Абон. активация',
                cell: (item, index) => <>
                    <div>{item.date_activated ? moment(item.date_activated).format('DD.MM.YYYY') : ''}</div>
                </>
            },
            {
                title: 'Абон. конец',
                cell: (item, index) => <>
                    <div>{item.date_end ? moment(item.date_end).format('DD.MM.YYYY') : ''}</div>
                </>
            }
        ]}
        data={data}
        dataPerPage={5}
    />
}