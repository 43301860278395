import React, { useState } from 'react';
import { Mic } from '../../../svg';

import './style.scss'

export default function Dictaphone({ onResult }) {

  const [isListening, setIsListening] = useState(false);

  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  if (!SpeechRecognition) return null;
  const recognition = new SpeechRecognition();

  recognition.lang = 'ru-RU';
  recognition.interimResults = false;
  recognition.maxAlternatives = 1;

  recognition.onresult = function (event) {
    const last = event.results.length - 1;
    const word = event.results[last][0].transcript
    onResult(word);
  };

  recognition.onspeechend = function () {
    recognition.stop();
    setIsListening(false);
  };

  function onMicClick() {
    isListening ? recognition.stop() : recognition.start();
    setIsListening(!isListening);
  }

  return (
    <div className='DictaphoneContainer' onClick={() => onMicClick()}>
      <Mic className={isListening ? 'listening' : 'default'} />
    </div>
  );

  // const {
  //   transcript,
  //   listening,
  //   //resetTranscript,
  //   browserSupportsSpeechRecognition
  // } = useSpeechRecognition();

  // useEffect(() => {
  //   onChange(transcript);
  //   // eslint-disable-next-line
  // }, [transcript]);

  // useEffect(() => {
  //   console.log(value);

  //   // eslint-disable-next-line
  // }, [value]);

  // if (!browserSupportsSpeechRecognition) {
  //   return <></>;
  // }

  // return (
  //   <div className='DictaphoneContainer' onClick={listening ? SpeechRecognition.stopListening : SpeechRecognition.startListening}>
  //     <Mic className={listening ? 'listening' : 'default'} />
  //   </div>
  // );
};
