// eslint-disable-next-line
import React, { useState, useEffect } from "react";


import "./style.scss";

export default function Select({ title, value, options, onChange, isPayment }) {
    return <div className="selectContainer">

        {isPayment ?
            <select
                className="isPayment"
                value={value}
                onChange={(event) => onChange(event.target.value)}>
                <option value={false}>Способ оплаты</option>
                <option value={'Карта'}>Карта</option>
                <option value={'Наличные'}>Наличные</option>
            </select>
            :
            <select
                value={value}
                onChange={(event) => onChange(event.target.value)}>
                <option value={''}>{title}</option>
                {options.map((element, index) => {
                    return <option key={`${element.value}-${index}`} value={element.value}>{element.label}</option>
                })}
            </select>}
    </div>
}

