import React from 'react';
import './style.scss';

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clickIndex: 0,
      clickPos: { x: 0, y: 0 },
      needConfirm: false,
    };

    this.Ref = React.createRef();
    this.seed = 'button-' + Math.floor(Math.random() * Math.pow(10, 10)).toString();
  }

  render() {
    const {
      action,
      GUIindex,
      type,
      children,
      className,
      disabled,
      style,
      theme = 'classic',
      useConfirm,
    } = this.props;

    return <>
      {!this.state.needConfirm && <button
        ref={this.Ref}
        style={style}
        className={`button${type ? ' ' + type : ''}${className ? ' ' + className : ''} ${theme}`}
        onClick={(event) => {
          if (useConfirm) {
            this.setState({ needConfirm: true });
          } else {
            action();
            const elementSizing = this.Ref.current.getBoundingClientRect()
            this.setState({ clickIndex: this.state.clickIndex + 1, clickPos: { x: event.clientX - elementSizing.left, y: event.clientY - elementSizing.top } });
          }
          event.persist();
          event.preventDefault();
          event.stopPropagation();
        }}
        disabled={disabled}
        tabIndex={GUIindex || -1}
      >
        <div className={'expander'} key={this.state.clickIndex} style={{ top: this.state.clickPos.y, left: this.state.clickPos.x }} />
        <div className={'buttonContent'}>
          {children}
        </div>
      </button>}

      {this.state.needConfirm &&
        <div className='buttonConfirm' style={style}>
          <div className='confirm' onClick={(event) => {
            action();
            this.setState({ needConfirm: false });
            event.persist();
            event.preventDefault();
            event.stopPropagation();
          }}>Да</div>
          <div className='confirm' onClick={(event) => {
            this.setState({ needConfirm: false })
            event.persist();
            event.preventDefault();
            event.stopPropagation();
          }}>Нет</div>
        </div>
      }

    </>
  }
}

export default Button;
