import React from 'react';
import './style.scss';


class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.seed='checkbox-'+Math.floor(Math.random()*Math.pow(10, 10)).toString();
    this.Ref = React.createRef();
  }

  render() {
    const {
      onSwitch,
      value,
      //GUIindex,
      customCheck,
      className,
      children,
    } = this.props;

    return (
      <div className={`checkBox${className?' '+className:''}`}>
        <div
          onClick={() => onSwitch(!value)}
          className='checkBoxBody'
          id={this.seed}
          ref={this.Ref}
          >
          {value?customCheck||
            <svg width="13" height="11" viewBox="0 0 13 11" fill="none" >
              <path d="M1 5L4.44815 8L11 2" stroke="inherit" strokeWidth="3"/>
            </svg>
          :null}
        </div>
        <label className='checkBoxName' htmlFor={this.seed} onClick={() => {
          onSwitch(!value);
          if (this.Ref.current) this.Ref.current.focus()
        }}>{children}</label>
      </div>
    )
  }
}

export default Checkbox;
