import React, { useEffect, useState } from "react";
import "./style.scss";

import AuthContext from "../AuthContext";
import useData from "../../Hooks/useData";
import moment from "moment/moment";

import Input from "../GUI/Input";
import Button from "../GUI/button";
import Table from "../GUI/table";
import Chart from '../GUI/chart';
import Preloader from "../GUI/preloader";

export default function VisitsScreen() {

    const context = React.useContext(AuthContext);

    const { getData } = useData();
    const [visits, setVisits] = useState([]);
    const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().add(1, 'day').format('YYYY-MM-DD'));
    const [clubs, setClubs] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setData();
        // eslint-disable-next-line
    }, [context.authData]);



    async function setData() {
        try {
            setIsLoading(true);
            const visitsRes = await getData({ type: 'client_visit_history', from: dateFrom, to: dateTo, search: search ? search : '' });
            setVisits(visitsRes);
            const clubs = await getData({ type: 'selectAll', from: 'club' });
            setClubs(clubs);

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        setChart();
      
        // eslint-disable-next-line
    }, [visits]);

    async function setChart() {
        const chartData = [];
        for (let i = 7; i < 24; i++) {
            chartData.push({ from: i, to: i + 1, value: 0 });
        };
        visits.forEach(visit => {
            if (!visit.date) return;
            const itemDate = `${+moment(visit.date).format('LT').split(':')[0]}`;
            chartData.forEach((item, index) => {
                if (itemDate >= item.from && itemDate <= item.to) {
                    chartData[index] = { from: item.from, to: item.to, value: chartData[index].value + 1 }
                }
            });
        });
        setChartData(chartData);
        setIsLoading(false);
    }

    return (
        <div className="VisitsScreen">

            <div className="VisitsFilter">
                <div className="VisitsFilterRow">
                    <div className="VisitsFilterItem">
                        <span>Дата от:</span>
                        <Input
                            type={'date'}
                            value={dateFrom}
                            onChange={(v) => setDateFrom(v)} />
                    </div>
                    <div className="VisitsFilterItem">
                        <span>Дата до:</span>
                        <Input
                            type={'date'}
                            value={dateTo}
                            onChange={(v) => setDateTo(v)} />
                    </div>
                </div>

                <Input title={'Поиск'} value={search} onChange={text => setSearch(text)} />

                <div className="VisitsFilterRow">
                    <span></span>
                    <Button
                        style={{ margin: '10px 0 0 0' }}
                        disabled={false}
                        action={async () => setData()}>
                        {`Применить`}
                    </Button>
                </div>
            </div>

            {isLoading ? <Preloader /> : <Chart data={chartData} />}

            <Table
                columns={[
                    {
                        title: '№',
                        cell: (item, index) => <>
                            {item.id}
                        </>
                    },
                    {
                        title: 'Клиент',
                        cell: (item, index) => <>
                            <div>{item.clientName}</div>
                        </>,

                    },
                    {
                        title: 'Карта клиента',
                        cell: (item, index) => <>
                            <div>{item.card}</div>
                        </>,

                    },
                    {
                        title: 'Клуб',
                        cell: (item, index) => <>
                            <div>{item.clubName}</div>
                        </>,
                    },
                    {
                        title: 'Администратор',
                        cell: (item, index) => <>
                            <div>{item.admin}</div>
                        </>,

                    },
                    {
                        title: 'Логин',
                        cell: (item, index) => <>
                            <div>{item.login}</div>
                        </>,

                    },
                    {
                        title: 'Дата входа',
                        cell: (item, index) => <>
                            <div>{moment(item.date).format('DD.MM.YYYY')}</div>
                        </>,

                    },
                    {
                        title: 'Время входа',
                        cell: (item, index) => <>
                            <div>{moment(item.date).format('LT')}</div>
                        </>,

                    },
                    {
                        title: 'Дата выхода',
                        cell: (item, index) => <>
                            <div>{item.date_out ? moment(item.date_out).format('DD.MM.YYYY LT') : '-'}</div>
                        </>,

                    },

                ]}
                data={visits}
                dataPerPage={40}
            />


        </div>
    );
}
