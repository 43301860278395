import React, { useState, useEffect } from "react";

import "./style.scss";
import Button from "../GUI/button";

import useAuth from "../../Hooks/useAuth";

import AuthContext from "../AuthContext";



export default function AuthScreen() {

    const context = React.useContext(AuthContext);

    const [loginCount, setLoginCount] = useState(0);
    const [password, setPassword] = useState('');
    const [login, setLogin] = useState('');

    const { authData, setAuthError, authError, doLogin } = useAuth();

    useEffect(() => {
        context.updateAuthData({ token: authData.token, user: authData.user });
        // eslint-disable-next-line
    }, [authData.token, authData.user]);

    function doAuth() {
        setAuthError(false);
        if (!login || login === '' || !password || password === '') return;
        doLogin({ login: login, password: password });
        setLoginCount(loginCount + 1);
    }

    return (
        <div className={"AuthScreen"}>

            <div className='authForm'>

                <div className='authNavigation'>
                    <div className={`authTab active`} >Войти</div>
                </div>

                {authError && <div className='authError'> {authError} </div>}

                <div className='authFormContent'>
                    <input value={login} onChange={(ev) => setLogin(ev.target.value)} placeholder='Логин' maxLength={30}></input>
                    <input value={password} onChange={(ev) => setPassword(ev.target.value)} placeholder='Пароль' type='password' maxLength={30}></input>
                </div>

                <Button disabled={loginCount > 10} style={{ margin: '15px' }} action={() => doAuth()}>{`Войти`}</Button>
            </div>

        </div>
    );
}
