// eslint-disable-next-line
import { useState, useEffect } from "react";
import axios from "axios";

import config from "../config";

export default function useAuth() {

    const [authData, setAuthData] = useState({});
    const [authError, setAuthError] = useState(null);

    function handleError(error) {
        console.log(error, error.response);
        if (error.response && error.response.data) {
            setAuthError(`Ошибка ${error.response.data.error ?? error}`);
        } else {
            setAuthError(`Ошибка ${error}`);
        }
    }

    async function doRegistration(data) {
        try {
            const res = await axios.post(`${config.hostname}/api/registration`,
                {
                    data: data
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                    }
                }
            );
            if (res.status === 200) {
                const token = res.data.token;
                if (token) {
                    console.log(res.data)
                    setAuthData(res.data);
                } else setAuthError(res.data ? res.data.error : `Ошибка авторизации`);
            } else {
                setAuthError(res.data ? res.data.error : `Ошибка ${res.status}`);
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function doLogin(data) {
        try {
            const res = await axios.post(`${config.hostname}/api/login`,
                {
                    data: data
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                    }
                }
            );
            if (res.status === 200) {
                setAuthData(res.data);
            } else {
                setAuthError(res.data ? res.data.error : `Ошибка ${res.status}`);
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function logOut() {
        try {
            axios.post(`${config.hostname}/api/logOut`,
                {
                    token: authData.token
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                    }
                }
            );
            setAuthData({});
        } catch (error) {
            console.log(error);
        }
    }

    return { authData, setAuthData, authError, setAuthError, doRegistration, doLogin, logOut };
}
