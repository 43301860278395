// eslint-disable-next-line
import { useState, useEffect } from "react";
import axios from "axios";

import config from "../config";

export default function useService() {

    const [getExportResult, setExportResult] = useState(false);

    async function getExport(params) {
        try {
            const res = await axios.post(`${config.hostname}/api/getExport`,
                {
                    params: params
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                    }
                }
            );
            setExportResult(res.data.data);
        } catch (error) {
            console.log(error);
            
        }
    }


    return { getExportResult , getExport };
}
