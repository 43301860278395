import React from "react";

import Table from '../GUI/table';
import moment from "moment/moment";
import { Cancel } from "../../svg";

import "./style.scss";


export default function SalesTable({ data, onSaleCancel }) {


    return <Table
        columns={[
            {
                title: '',
                cell: (item, index) =>
                    <div className='CancelSales'
                        title='Отмена продажи'
                        onClick={() => {
                            onSaleCancel(item.id)
                        }}>
                        <Cancel />
                    </div>
            },
            {
                title: '№',
                cell: (item, index) => <>
                    <div>{item.id}</div>
                </>
            },
            {
                title: 'Администратор',
                cell: (item, index) => <>
                    <div>{item.admin}</div>
                    <div>{item.clubid}</div>
                </>,
                sorter: (a, b) => {
                    if (a.admin > b.admin) {
                        return 1
                    } else if (a.admin < b.admin) {
                        return -1
                    } else {
                        return 0
                    }
                }
            },
            {
                title: 'Наименование',
                cell: (item, index) => <>
                    <div>{item.name}</div>
                </>
            },
            {
                title: 'Дата',
                cell: (item, index) => <>
                    <div>{moment(item.date).format('DD.MM.YYYY LT')}</div>
                </>
            },
            {
                title: 'Цена',
                cell: (item, index) => <>
                    <div>{item.cost}</div>
                </>
            },
            {
                title: 'Кол-во',
                cell: (item, index) => <>
                    <div>{item.amount}</div>
                </>
            },
            {
                title: 'Способ оплаты',
                cell: (item, index) => <>
                    <div>{item.pay ? item.pay : ''}</div>
                </>
            },
            {
                title: 'Тип',
                cell: (item, index) => <>
                    <div>{item.type}</div>
                </>,
                sorter: (a, b) => {
                    if (a.type > b.type) {
                        return 1
                    } else if (a.trainer < b.type) {
                        return -1
                    } else {
                        return 0
                    }
                }
            }, {
                title: 'Клиент',
                cell: (item, index) => <>
                    <div>{item.client}</div>
                </>
            }, {
                title: 'Тренер',
                cell: (item, index) => <>
                    <div>{item.trainer}</div>
                </>,
                sorter: (a, b) => {
                    if (a.trainer > b.trainer) {
                        return 1
                    } else if (a.trainer < b.trainer) {
                        return -1
                    } else {
                        return 0
                    }
                }
            }
        ]}
        data={data}
        dataPerPage={40}
    />

}