import React, { useEffect, useState } from "react";

import { Archive } from "../../svg";

import AuthContext from "../AuthContext";

import Button from "../GUI/button";
import Table from '../GUI/table';
import Input from '../GUI/Input';
import Select from "../GUI/select";

import useData from "../../Hooks/useData";
import useUpdate from "../../Hooks/useUpdate";
import useInsert from "../../Hooks/useInsert";
import moment from "moment/moment";

import "./style.scss";

export default function GiftsScreen() {
    const authContext = React.useContext(AuthContext);

    const [abonemets, setAbonements] = useState([]);
    const [tab, setTab] = useState(1);

    const [newClientAbonement, setNewClientAbonement] = useState(false);
    const [newClientDateBuy, setNewClientDateBuy] = useState(moment().format('YYYY-MM-DD'));
    const [newClientDateEnd, setNewClientDateEnd] = useState(moment().format('YYYY-MM-DD'));
    const [pay, setPay] = useState(false);

    const { getData } = useData();
    const { result, update } = useUpdate();
    const { insertResult, insert } = useInsert();

    const [clubs, setClubs] = useState([]);
    const [salesAbonement, setSalesAbonement] = useState([]);

    useEffect(() => {
        if (result || insertResult) {
            setData();
        }
        // eslint-disable-next-line
    }, [result, insertResult]);

    useEffect(() => {
        if (authContext.authData) setData();
        // eslint-disable-next-line
    }, [authContext.authData]);

    async function setData() {
        const abonement = await getData({ type: 'gifts_type', id: authContext.authData.user.clubid });
        const sales = await getData({ type: 'gifts' });
        const clubs = await getData({ type: 'selectAll', from: 'club' });
        setClubs(clubs);
        setAbonements(abonement.length ? abonement.reverse() : []);
        setSalesAbonement(sales.length ? sales.reverse() : []);
    }

    async function updateAbonementTypes(v) {
        await update({ type: 'abonement_type', field: v.field, value: v.value ? v.value : '', id: v.id });
    }

    function renderAbonements(disabled) {
        return <Table
            columns={[
                {
                    title: '№',
                    cell: (item, index) => <>
                        {item.id}
                    </>,
                    sorter: (a, b) => (a.id - b.id)
                },
                {
                    title: 'Наименование',
                    cell: (item, index) => <>
                        <div>

                            <Input placeholder={'Название'} value={item.name} onChange={text => updateAbonementTypes({ id: item.id, field: 'name', value: text })} />

                        </div>
                    </>,
                    sorter: (a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else if (a.name < b.name) {
                            return -1
                        } else {
                            return 0
                        }
                    },
                    filter: (item, value) => item.name.toString().toLowerCase().includes(value.toString().toLowerCase()),
                },
                {
                    title: 'Кол-во посещен.',
                    cell: (item, index) => <>
                        <div style={{ maxWidth: '100px' }}>

                            <Input placeholder={''} type="number" value={item.visits} onChange={text => updateAbonementTypes({ id: item.id, field: 'visits', value: text })} />

                        </div>
                    </>
                },
                {
                    title: 'Клуб',
                    cell: (item, index) => <>

                        <Select
                            value={item.club}
                            title={'Выбрать клуб'}
                            options={clubs.map((item) => {
                                return {
                                    value: `${item.name}`,
                                    label: `${item.name}`,
                                }
                            })}
                            onChange={(v) => updateAbonementTypes({ id: item.id, field: 'club', value: v })}
                        />
                    </>,
                    sorter: (a, b) => {
                        if (a.club > b.club) {
                            return 1
                        } else if (a.club < b.club) {
                            return -1
                        } else {
                            return 0
                        }
                    },
                    filter: (item, value) => item.club.toString().toLowerCase().includes(value.toString().toLowerCase()),
                },
                {
                    title: 'Цена (Руб.)',
                    cell: (item, index) => <>

                        <Input placeholder={''} type="number" value={item.cost} onChange={text => updateAbonementTypes({ id: item.id, field: 'cost', value: text })} />

                    </>,
                    sorter: (a, b) => (a.cost - b.cost),
                },
                {
                    title: 'Дл-сть (Мес.)',
                    cell: (item, index) => <>

                        <Input placeholder={''} type="number" value={item.duration} onChange={text => {
                            updateAbonementTypes({ id: item.id, field: 'duration', value: text });
                            switch (text) {
                                case '1':
                                    updateAbonementTypes({ id: item.id, field: 'freezingdays', value: 7 });
                                    break;
                                case '3':
                                    updateAbonementTypes({ id: item.id, field: 'freezingdays', value: 14 });
                                    break;
                                case '6':
                                    updateAbonementTypes({ id: item.id, field: 'freezingdays', value: 30 });
                                    break;
                                case '12':
                                    updateAbonementTypes({ id: item.id, field: 'freezingdays', value: 60 });
                                    break;
                                default:
                                    break;
                            }
                        }} />

                    </>,
                    sorter: (a, b) => (a.duration - b.duration),
                },
                {
                    title: 'Время посещен.',
                    cell: (item, index) => <>

                        <Input placeholder={''} value={item.time} onChange={text => updateAbonementTypes({ id: item.id, field: 'time', value: text })} />

                    </>,
                    sorter: (a, b) => (a.time - b.time),
                },
                {
                    title: 'Заморозка (Дн.)',
                    cell: (item, index) => <>
                        <Input placeholder={''} type="number" value={item.freezingdays} onChange={text => updateAbonementTypes({ id: item.id, field: 'freezingdays', value: text })} />
                    </>,
                    sorter: (a, b) => (a.freezingdays - b.freezingdays),
                },
                {
                    title: 'Заморозка',
                    cell: (item, index) => <div className="row">
                        <input type="checkbox" name={'name'} checked={item.canfreeze} value="1" onChange={val => updateAbonementTypes({ id: item.id, field: 'canfreeze', value: !item.canfreeze })} />
                        <div title="В Архив" className="Archive"><Archive onClick={() => {
                            if (disabled) {
                                update({ type: 'abonement_type', field: 'disabled', value: 0, id: item.id });
                            } else {
                                update({ type: 'abonement_type', field: 'disabled', value: 1, id: item.id });
                            }
                        }} /></div>
                    </div>
                },
                // {
                //     title: '30 дн.',
                //     cell: (item, index) => <>
                //         <input type={'checkbox'} value={item.activation === '30' ? true : false} checked={item.activation === '30' ? true : false}
                //             onChange={text => { updateAbonementTypes({ id: item.id, field: 'activation', value: item.activation === '30' ? null : '30' }) }} />
                //     </>
                // },
                // {
                //     title: 'Срок активации',
                //     cell: (item, index) => <>
                //         {item.activation === '30' ? '30дн.' : <input className='date' value={item.activation} type={'date'} onChange={event => updateAbonementTypes({ id: item.id, field: 'activation', value: event.target.value })} />}
                //     </>
                // }
            ]}
            data={abonemets.filter(a => a.disabled === disabled)}
            dataPerPage={30}
        />
    }

    function renderSalesAbonements() {
        return <Table
            columns={[
                {
                    title: '№',
                    cell: (item, index) => <> {item.id} </>,
                    sorter: (a, b) => (a.id - b.id)
                },
                {
                    title: 'Клиент',
                    cell: (item, index) => <>{item.owner}</>,
                },
                {
                    title: 'Дата',
                    cell: (item, index) => <>{moment(item.date_created).format('DD.MM.YYYY')}</>,
                },
                {
                    title: 'Дата активации',
                    cell: (item, index) => <>{item.date_activated ? moment(item.date_activated).format('DD.MM.YYYY') : ''}</>,
                },
                {
                    title: 'Наименование',
                    cell: (item, index) => <>{item.name}</>,
                },
                {
                    title: 'Цена',
                    cell: (item, index) => <>{item.cost}</>,
                },
                {
                    title: 'Длительность',
                    cell: (item, index) => <>{item.duration}</>,
                },
                {
                    title: 'Клуб',
                    cell: (item, index) => <>{item.club}</>,
                },
                {
                    title: 'Администратор',
                    cell: (item, index) => <>{item.fullname}</>,
                }
            ]}
            data={salesAbonement}
            dataPerPage={30}
        />
    }

    function onAbonementSelect(v) {
        const abon = abonemets.find(item => item.id == v);
        setNewClientAbonement(abon);
        if (!(abon.activation === '30') && !(moment(abon.activation).isValid())) {
            console.log('У абонемента не правильно указан срок активации')
        }
        if (abon.duration !== 0) {
            setNewClientDateEnd(moment(newClientDateBuy).add(abon.duration, 'month').format('YYYY-MM-DD'));
        } else {
            setNewClientDateEnd(moment(newClientDateBuy).add(1, 'day').format('YYYY-MM-DD'));
        }
    }

    return (
        <div className="GiftsScreen">
            <div className="Header">{`Продажа нового подарочного абонемента`}</div>

            <div className="Card">

                <div className="ServiceSelect">
                    <div className="newClientAbonement">
                        {`${newClientAbonement.name || ''} ${newClientAbonement.cost ? `Цена: ${newClientAbonement.cost}` : ''}`}
                    </div>
                    <Select
                        value={newClientAbonement.id}
                        title={'Наименование'}
                        options={abonemets.map((item) => {
                            return {
                                value: item.id,
                                label: `${item.name} - ${item.cost}р. ${item.club}`,
                            }
                        })}
                        onChange={(v) => onAbonementSelect(v)}
                    />
                </div>
                <div className="CardItem">
                    <Select isPayment={true} value={pay} onChange={(v) => setPay(v)} />
                </div>
                {/* <div className="CardItemSpace">
                    <div>Дата начала действия абонемента</div>
                    <Input
                        type={'date'}
                        value={newClientDateBuy}
                        onChange={(v) => setNewClientDateBuy(v)} />
                </div>
                <div className="CardItemSpace">
                    <div>Дата окончания действия абонемента</div>
                    <b>{moment(newClientDateEnd).format('DD.MM.YYYY')}</b>
                </div> */}
                <div className="ControlRow">
                    <Button
                        disabled={!pay || !newClientAbonement}
                        action={async () => {
                            await insert({
                                type: 'abonement',
                                value: {
                                    data: newClientAbonement,
                                    owner: null,
                                    date_buy: newClientDateBuy,
                                    pay: pay,
                                    user: authContext.authData.user,
                                    type: newClientAbonement.id,
                                    need_activation: moment().add(30, 'days').format('YYYY-MM-DD'),
                                    isgift: true,
                                }
                            });
                            setNewClientAbonement(false);
                        }}>
                        {`Продажа`}
                    </Button>
                </div>
            </div>



            <div className="Header">{`Список проданных подарочных абонементов`}</div>
            {renderSalesAbonements()}


            <div className="Header">{`Подарочные абонементы`}</div>

            <div className="TypesTabs">
                <div className={`TypesTab ${tab === 1 && 'active'}`} onClick={() => setTab(1)}>Активные</div>
                <div className={`TypesTab ${tab === 2 && 'active'}`} onClick={() => setTab(2)}>Архив</div>
            </div>

            <div className="ControlRow">
                <Button style={{ margin: '0 0 10px 10px' }} action={() => insert({
                    type: 'gifts_type',
                    value: {
                        id: authContext.authData.user.clubid,
                        isgift: true,
                    }
                })}>{`Добавить`}</Button>
            </div>

            {tab === 1 && renderAbonements(0)}
            {tab === 2 && renderAbonements(1)}






        </div>
    );
}
