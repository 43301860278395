import React, { useEffect, useState } from "react";
import "./style.scss";

import Preloader from '../Preloader';
import axios from 'axios';

import Modal from './modal';
import Input from '../../GUI/Input';
import Button from '../../GUI/button';
import moment from "moment/moment";
import config from "../../../config";
import mainLogo from '../../../img/Frame_71.png';

export default function PersonalAccount() {

    //const host = "https://crm.fitness-juice.ru";
    const host = config.hostname;

    const [show, setShow] = useState(false);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loginCount, setLoginCount] = useState(0);
    const [error, setError] = useState(null);

    const [showExpired, setShowExpired] = useState(false);

    const [abonements, setAbonements] = useState([]);
    const [status, setStatus] = useState(500);

    const [needToFreeze, setNeedToFreeze] = useState(null);
    const [freezeValue, setFreezeValue] = useState(null);

    useEffect(() => {
        if (`${window.location.href}`.includes('lk')) setShow(true);
        // eslint-disable-next-line
    }, [window.location.href]);

    async function doLogin() {
        try {
            const res = await axios.post(`${host}/api/getAccount`,
                {
                    params: {
                        login,
                        password,
                    },
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                    }
                }
            );
            if (res.status === 200) {
                console.log(res);
                console.log(res.data.abonements);
                setStatus(200);
                setAbonements(res.data.abonements);
            }
        } catch (e) {
            setError("Неправильный логин или пароль");
        }
    }

    async function doFreeze() {
        try {
            const res = await axios.post(`${host}/api/doFreezeAccount`,
                {
                    params: {
                        login,
                        password,
                        needToFreeze: needToFreeze.id,
                        freezeValue,
                    },
                },
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                    }
                }
            );
            console.log(res.status);
            if (res.status === 200) {

            }
        } catch (e) {
            setStatus(500);
            setError("Ошибка попробуйте позже");
        }
    }

    function setFreezeAbonement(days, item) {
        if (days <= 0 || days > 60) return;
        const abonement = abonements.find(i => i.id === item.id);
        abonement.needToFreeze = { ...abonement };
        abonement.freezeValue = days;
        setNeedToFreeze(abonement);
        setFreezeValue(days);
    }

    async function freezeAbonement(confirm, item) {
        if (confirm) {
            await doFreeze();
        }
        const abonement = abonements.find(i => i.id === item.id);
        abonement.needToFreeze = null;
        abonement.freezeValue = null;
        if (confirm) abonement.alreadyFreeze = true;
        setNeedToFreeze(null);
        setFreezeValue(null);
    }

    function renderAbonement(item, index) {
        let times = `${item.time}`.split('-');
        if (!times || !times.length) times = ['', ''];
        return <div className={`Abonement ${isExpired(item) ? 'Expired' : ''}`} key={index}>

            <div className="row nonBorder">
                <div className="Club">{item.club}</div>
                {item.time === "7-11" && <div className="TimeName">"Жаворонок"</div>}
                {item.time === "7-17" && <div className="TimeName">"Дневной"</div>}
                {item.time === "20-23" && <div className="TimeName">"Сова"</div>}
                {item.time === "7-23" && <div className="TimeName">"Полный день"</div>}
            </div>

            {item.date_end ? null : <div className="row nonBorder">
                <div className="NotActive">Абонемент не активирован</div>
            </div>}

            {item.duration === 0 ?
                <div className="row">
                    <div>Кол-во посещений:</div>
                    <div>{`${item.visits}`}</div>
                </div> :
                <div className="row">
                    <div>Длительность:</div>
                    <div>{`${item.duration} мес.`}</div>
                </div>
            }

            <div className="row">
                <div>Время посещения:</div>
                <div>{`${times[0]}:00 - ${times[1]}:00`}</div>
            </div>

            {item.date_end ? <div className="row delimiter">
                <div>Действителен до:</div>
                <div>{`${moment(item.date_end).format("DD.MM.YYYY")}`}</div>
            </div> : null}

            <div className="row">
                <div>Дней заморозки:</div>
                <div>{`${item.freezingdays}`}</div>
            </div>

            {item.date_unfreeze ?
                <div className="row nonBorder">
                    <div>Абонемент заморожен до:</div>
                    <div className="Freeze">{`${moment(item.date_unfreeze).format("DD.MM.YYYY")}`}</div>
                </div> :
                item.date_end ? !isExpired(item) && <div className="row nonBorder delimiter">Заморозить: </div> : null
            }

            {(item.date_unfreeze || !item.date_end || item.alreadyFreeze || isExpired(item)) ?
                item.alreadyFreeze && <div className="alreadyFreeze">Абонемент заморожен</div>
                :
                <div className="row nonBorder">
                    <div className="FreezeAbon" onClick={() => setFreezeAbonement(7, item)}>7</div>
                    <div className="FreezeAbon" onClick={() => setFreezeAbonement(14, item)}>14</div>
                    <div className="FreezeAbon" onClick={() => setFreezeAbonement(30, item)}>30</div>
                    <div className="FreezeAbon" onClick={() => setFreezeAbonement(item.freezingdays, item)}>{item.freezingdays}</div>
                </div>
            }

            {item.needToFreeze && <div className="Confirm">
                <div className="ConfirmTitle">{`Заморозить на ${item.freezeValue} дней?`}</div>
                <div className="ConfirmRow">
                    <div className="ConfirmButton" onClick={() => freezeAbonement(true, item)}>Да</div>
                    <div className="ConfirmButton" onClick={() => freezeAbonement(false, item)}>Нет</div>
                </div>
            </div>}
        </div>
    }

    function isExpired(item) {
        if (item.activated === 0 ||
            (moment(item.date_end).isSameOrAfter(moment(), 'year') &&
                moment(item.date_end).isSameOrAfter(moment(), 'month') &&
                moment(item.date_end).isSameOrAfter(moment(), 'date'))) {
            return false
        } else return true
    }

    function renderAbonements() {
        if (!abonements?.length) {
            return <div style={{ color: 'red', fontWeight: '500' }}>Абонементы отсутствуют</div>
        } else {
            return abonements.map((item, index) => { // eslint-disable-line
                if (showExpired) {
                    return renderAbonement(item, index);
                } else {
                    if (!isExpired(item)) {
                        return renderAbonement(item, index);
                    }
                }
            })
        }
    }

    return (<>
        {show && <div className="PersonalAccount">

            <Modal show>

                <div className="LogoContainer">
                    <img src={mainLogo} style={{ width: '220px' }} alt="mainLogo" />
                </div>

                {status === 200 ?
                    <>
                        <div className="ClientLogin">#{login}</div>
                        <div className="Title">Абонементы</div>
                        <div className="AbonementsMenu">
                            <div
                                onClick={() => setShowExpired(false)}
                                className={`${showExpired ? '' : 'active'}`}>
                                Только активные
                            </div>
                            <div
                                onClick={() => setShowExpired(true)}
                                className={`${showExpired ? 'active' : ''}`}>
                                Все абонементы
                            </div>
                        </div>
                        <div className="AbonementsContainer">{renderAbonements(showExpired)}</div>
                    </>
                    :
                    <div className="AuthContainer">
                        <div className="AuthInfo">
                            Авторизуйтесь чтобы просматривать данные о своём абонементе. Логин и пароль вы можете получить у администратора в клубе.
                        </div>

                        <Input
                            title={'Логин'}
                            margin={`15px`}
                            value={login}
                            onChange={text => {
                                if ((/^\d+$/.test(`${text}`) || text === '')) setLogin(`${text}`);
                                setError(null);
                            }}
                        />
                        <Input
                            title={'Пароль'}
                            margin={`0 15px 0 15px`}
                            value={password}
                            onChange={text => {
                                setPassword(`${text}`.toLowerCase());
                                setError(null);
                            }}
                            type={showPassword ? "" : "password"}
                        />
                        <div className="ControlRow">
                            <div className="ShowPassword" onClick={() => setShowPassword(!showPassword)}>{showPassword ? "скрыть пароль" : "показать пароль"}</div>
                        </div>

                        <div className="ControlRow">
                            <Button
                                disabled={loginCount > 10 || !login || !password || login === '' || password === ''}
                                style={{ margin: '0 15px 15px 15px', background: '#2b2a29'}}
                                action={async () => {
                                    await doLogin();
                                    setLoginCount(loginCount + 1);
                                }}>{`Войти`}</Button>
                        </div>

                        <div className="ControlRow">
                            {error && <div className="Error">{error}</div>}
                        </div>
                    </div>


                }


            </Modal>

        </div>}
    </>
    );
}
