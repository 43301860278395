import React, { useEffect, useState } from "react";

import useData from "../../../Hooks/useData";
import Table from "../../GUI/table";
import moment from "moment/moment";



export default function ClientSales({ tab, id }) {

    const [data, setData] = useState([]);
    const { getData } = useData();

    async function fetchData() {
        const response = await getData({ type: 'selectAll', from: 'client_buy_history', conditionName: 'clientid', condition: id });
        setData(response);
    }

    useEffect(() => {
        if (tab === 3) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [tab]);

    return (
        <div className="ClientSales">
            {data.length ? <Table
                onRowClick={(v) => {

                }}
                columns={[
                    {
                        title: '',
                        cell: (item, index) => <>
                           {data.length - index}
                        </>
                    },
                    {
                        title: 'Наименование',
                        cell: (item, index) => <>
                            <div style={{ minWidth: '400px' }}>
                                {item.text}
                            </div>
                        </>,
                        sorter: (a, b) => {
                            if (a.text > b.text) {
                                return 1
                            } else if (a.text < b.text) {
                                return -1
                            } else {
                                return 0
                            }
                        },
                        filter: (item, value) => `${item.text}`.toString().toLowerCase().includes(`${value}`.toString().toLowerCase()),
                    },
                    {
                        title: 'Дата',
                        cell: (item, index) => <>
                            <div>
                                {moment(item.date).format('DD.MM.YYYY LT')}
                            </div>
                        </>,
                        filter: (item, value) => `${moment(item.date).format('DD.MM.YYYY LT')}`.toString().toLowerCase().includes(`${value}`.toString().toLowerCase()),
                    },
                    {
                        title: 'Цена',
                        cell: (item, index) => <>
                            {item.cost}
                        </>
                    }
                ]}
                data={data}
                dataPerPage={30}
            /> : <div className="GlobalHeader">Нет данных</div>}

        </div>
    );
}
