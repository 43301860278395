import React from 'react';
import './style.scss';

class Preloader extends React.Component {
  render() {
    return (
      <div className='preloaderShutter'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-50 -50 100 100">
          <g fill="none" strokeWidth="6" stroke="var(--basedim)">
            <circle strokeOpacity=".5" r="47"></circle>
            <path d="M0,-47 a47,47 0 0,1 47,47" strokeWidth="6" stroke="var(--blue)" className='carriage'></path>
          </g>
        </svg>
      </div>
    )
  }
  
}

export default Preloader;
