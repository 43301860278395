import React from "react";
import "./style.scss";

export default class Preloader extends React.Component {
    render() {
        return <div className="Preloader">
            <div className="container">
                <div className="circle circle-1"></div>
                <div className="circle circle-2"></div>
                <div className="circle circle-3"></div>
                <div className="circle circle-4"></div>
                <div className="circle circle-5"></div>
            </div>
        </div>
    }
}
