import React, { useEffect, useState } from "react";

import useData from "../../../Hooks/useData";
import Table from "../../GUI/table";
import moment from "moment/moment";
import Button from "../../GUI/button";


export default function ClientVisits({ tab, id, onIn, onExit, result }) {

    const [data, setData] = useState([]);
    const { getData } = useData();

    async function fetchData() {
        const response = await getData({ type: 'clientVisitHistory', id:id });
        setData(response.reverse());
    }

    useEffect(() => {
        if (tab === 2) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [tab]);

    useEffect(() => {
        if (result) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [result]);

    return (
        <div className="ClientVisits">
            <div className="VisitsRow">
                <Button style={{ margin: '0 10px 0 0' }} action={() => {
                    onIn();
                }}>{`Вход`}</Button>
                <Button action={() => {
                    onExit();
                }}>{`Выход`}</Button>
            </div>
            {data.length ? <Table
                onRowClick={(v) => {

                }}
                columns={[
                    {
                        title: '',
                        cell: (item, index) => <>
                            {data.length - index}
                        </>
                    },
                    {
                        title: 'Дата / Время - Вход',
                        cell: (item, index) => <>
                            <div>
                                <p>{moment(item.date).format('DD.MM.YYYY, LT')}</p>
                            </div>
                        </>
                    },
                    {
                        title: 'Дата / Время - Выход',
                        cell: (item, index) => <>
                            <div>
                                <p>{item.date_out ? moment(item.date_out).format('DD.MM.YYYY, LT') : ''}</p>
                            </div>
                        </>
                    },
                    {
                        title: 'Администратор',
                        cell: (item, index) => <>
                            <div>
                                <p>{item.fullname}</p>
                            </div>
                        </>
                    },
                    {
                        title: 'Клуб',
                        cell: (item, index) => <>
                            <div>
                                <p>{item.name}</p>
                            </div>
                        </>
                    },
                    {
                        title: '-',
                        cell: (item, index) => <>
                            <div>
                                <p>{item.info ? item.info : ''}</p>
                            </div>
                        </>, sorter: (a, b) => {
                            if (a.info) {
                                return -1
                            } else {
                                return 1
                            }
                        },
                    }
                ]}
                data={data}
                dataPerPage={30}
            /> : <div className="GlobalHeader">Нет данных</div>}

        </div>
    );
}
