import React from 'react';
import './style.scss';
import Modal from '../modal';
import Input from '../Input';

export default class Table extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: [],
            filtermodal: false,
            sorter: 0,
            sorterdir: 0,
            page: 0,
        }
    }

    applySorter = index => {
        if (this.state.sorter === index) {
            if (this.state.sorterdir) this.setState({ sorterdir: this.state.sorterdir * (-1) });
            else this.setState({ sorterdir: 1 });
        } else {
            this.setState({ sorter: index, sorterdir: 1 });
        }
    }

    render() {
        const {
            columns,
            dataPerPage,
            className,
            onRowClick
        } = this.props;
        const {
            filters,
            filtermodal,
            sorter,
            sorterdir,
            page,
        } = this.state;
        let data = [...(this.props.data || [])];
        filters.forEach((filtervalue, index) => {
            if (!filtervalue || filtervalue === '' || !filtervalue.length) return
            data = data.filter(datum => columns[index].filter(datum, filtervalue));
        })
        if (sorterdir) {
            data = data.sort(columns[sorter].sorter)
            if (sorterdir === -1) data.reverse();
        }

        return <>
            <table
                cellPadding={0}
                cellSpacing={0}
                className={`${className ? `${className} ` : ''}Table`}>
                <thead>
                    <tr>
                        {columns.map((column, index) => <th key={'th-' + index}>
                            <div className='HeaderWrap'>
                                <span>{column.title}</span>
                                {column.filter && <svg viewBox="0 0 512 512" className='filter' onClick={() => this.setState({ filtermodal: index })}>
                                    <polygon points="0,29.509 0,101.003 200.784,301.787 200.784,454.883 311.216,482.491 311.216,301.786 512,101.002 512,29.509" />
                                </svg>}
                                {column.filter && <Modal title={'Фильтр'} visible={filtermodal === index} onClose={() => this.setState({ filtermodal: false })}>
                                    <Input
                                        placeholder={column.title}
                                        value={this.state.filters[index] ? this.state.filters[index] : ''}
                                        onChange={text => this.setState(state => {
                                            const filters = state.filters;
                                            filters[index] = text;
                                            return { filters }
                                        })} />
                                </Modal>}
                                {column.sorter && <svg onClick={() => this.applySorter(index)} viewBox="0 0 255 255" className={`sorter ${sorterdir === 1 ? 'up' : ''}${sorterdir === -1 ? 'down' : ''} ${((sorter === index) && sorterdir) ? 'show' : ''}`}>
                                    <polygon points="0,63.75 127.5,191.25 255,63.75" />
                                </svg>}
                            </div>
                        </th>)}
                    </tr>
                </thead>
                <tbody>
                    {(dataPerPage ? [...data].splice(dataPerPage * page, dataPerPage) : data).map((datum, index) =>
                        <tr key={'tr-' + (datum.id)} className={onRowClick ? 'onRowClick' : ''} onClick={() =>onRowClick ? onRowClick(datum): ()=>{}}>
                            {columns.map((column, tdindex) => <td key={'td-' + (datum.id) + '-column-' + (tdindex)}>
                                {column.cell(datum, index)}
                            </td>)}
                        </tr>)}
                </tbody>
            </table>
            {dataPerPage && <div className='Pagination'>
                {(() => {
                    const totalpages = Math.ceil(data.length / dataPerPage);
                    let pages = totalpages;
                    const pagination = []
                    while (pages--) {
                        const pageController = pages;
                        if (Math.abs(page - pages) === 3) { pagination.unshift(<div key={`placeholder-${pageController}`} className='pageSwitch empty'>...</div>) }
                        if (!(pages > 2 && pages < (totalpages - 3)) || Math.abs(page - pages) < 3) {
                            pagination.unshift(<div key={'page-' + pageController} className={`pageSwitch${pageController === page ? ' active' : ''}`} onClick={() => this.setState({ page: pageController })}>{pageController + 1}</div>)
                        }
                    }
                    return pagination
                })()}
            </div>}
        </>
    }
}